
import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Link } from "react-router-dom";
// import LeadIcon from './lead.svg';
import './blur.css';
import '../../Config/loading.css';

export default function Dashboard() {

    const [userDetails, SetUserDetails] = useState([]);

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    const [dashboardData, SetDashboardData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_CRM_URL}dashboard/GetDashboardData`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();

                if (data.Status) {
                    SetDashboardData(data.ResultData);
                    setLoading(false);
                } else {
                    console.error("Fetched data is not an array:", data);
                    SetDashboardData([]);
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <Base>
            <div className="container" >
                {loading ? <p className="laoding-text">
                    <div className="containers">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                    </div>
                </p> : ''}

                <div className="row">
                    <div className="col-xl-3 col-sm-6 col-6">
                        <Link to="/customers" style={{ textDecoration: "none", color: "inherit", display: "flex" }}>
                            <div className="dash-count">
                                <div className="dash-counts">
                                    <h4>{dashboardData?.TotalCustomers}</h4>
                                    <h5>Customers</h5>
                                </div>
                                <div className="dash-imgs">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                        className="feather feather-user">
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-xl-3 col-sm-6 col-6 ">
                    <Link to="/leads" style={{ textDecoration: "none", color: "inherit", display: "flex" }}>
                        <div className="dash-count das1">
                            <div className="dash-counts">
                                <h4>{dashboardData?.TotalLeads}</h4>
                                <h5>Leads</h5>
                            </div>
                            <div className="dash-imgs">
                                <i className="fa-solid fa-headset textx-white"></i>
                                {/* <img src={LeadIcon} /> */}
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-6 ">
                    <Link to="/followups" style={{ textDecoration: "none", color: "inherit", display: "flex" }}>
                        <div className="dash-count das2">
                            <div className="dash-counts">
                                <h4>{dashboardData?.TotalFollowups}</h4>
                                <h5>Followups</h5>
                            </div>
                            <div className="dash-imgs">
                                <i className="fa-regular fa-comments text-white"></i>
                                {/* <img src="assets/img/icons/file-text-icon-01.svg" className="img-fluid" alt="icon"/> */}
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-6 ">
                    <Link to="/reminders" style={{ textDecoration: "none", color: "inherit", display: "flex" }}>
                        <div className="dash-count das3">
                            <div className="dash-counts">
                                <h4>{dashboardData?.TotalReminders}</h4>
                                <h5>Remidners</h5>
                            </div>
                            <div className="dash-imgs">
                                <i className="fa-regular fa-bell text-white"></i>
                            </div>
                        </div>
                        </Link>
                    </div>
                    
                </div>


                <div className="row">
                    <div className="col-xl-4 col-md-4 col-sm-12 col-12 d-flex">
                        <div className="card flex-fill default-cover mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h4 className="card-title mb-0 text-info">Recent Leads</h4>
                                <div className="view-all-link">
                                    <Link to='/leads' className="view-all d-flex align-items-center">
                                        View All<i className="fa-solid fa-arrow-right ms-2"></i>
                                    </Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive dataview">
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th>Property</th>
                                                <th>Name</th>
                                                <th>Mobile</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dashboardData.RecentLeads && dashboardData.RecentLeads.map((item, index) => (
                                                <tr key={index}>
                                                    <td >
                                                        {item.Company}
                                                    </td>
                                                    <td>{item.Name}</td>
                                                    <td>{item.phonenumber}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-md-4 col-sm-12 col-12 d-flex">
                        <div className="card flex-fill default-cover mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h4 className="card-title mb-0 text-info">Recent Customers</h4>
                                <div className="view-all-link">
                                    <Link to='/customers' className="view-all d-flex align-items-center">
                                        View All<i className="fa-solid fa-arrow-right ms-2"></i>
                                    </Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive dataview">
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th>Property</th>
                                                <th>Name</th>
                                                <th>Mobile</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dashboardData.RecentCustomers && dashboardData.RecentCustomers.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {item.Company}
                                                    </td>
                                                    <td>{item.VAT}</td>
                                                    <td>{item.phonenumber}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-md-4 col-sm-12 col-12 d-flex">
                        <div className="card flex-fill default-cover mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h4 className="card-title mb-0 text-info">Recent Followups</h4>
                                <div className="view-all-link">
                                    <Link to='/followups' className="view-all d-flex align-items-center">
                                        View All<i className="fa-solid fa-arrow-right ms-2"></i>
                                    </Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive dataview">
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dashboardData.RecentFollowups && dashboardData.RecentFollowups.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.RelType}</td>
                                                    <td>{item.FollowupType}</td>
                                                    <td className="text-warning">{item.FollowupStatus}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer
                style={{
                    position: "fixed",
                    bottom: 0,
                    width: "100%",
                    backgroundColor: "#000a4f",
                    color: "white",
                    clipPath: "ellipse(85% 90% at 50% 100%)",
                    zIndex: 1000,
                    animation: "slideUp 1s ease-out forwards",
                }}
                className="py-4 d-md-none animated-footer"
            >
                <div className="container-fluid">
                    <div className="row text-center pt-2">
                        <div className="col">
                            <Link to="/leads">
                                <i className="fa-solid fa-headset text-white fa-lg pulsing-icon"></i>
                                <div className="text-white">Leads</div>
                            </Link>
                        </div>
                        <div className="col">
                            <i className="fa-solid fa-sack-dollar fa-lg pulsing-icon"></i>
                            <div>Expenses</div>
                        </div>
                        <div className="col">
                            <i className="fa-solid fa-list-check fa-lg pulsing-icon"></i>
                            <div>Followups</div>
                        </div>
                    </div>
                </div>
            </footer>

        </Base>
    )
}