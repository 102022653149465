import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import { API_BASE_CRM_URL } from "../../Config/Config";
import "../../Config/loader.css";
import Pagination from "../../Config/Pagination";
import {
  startOfWeek,
  startOfMonth,
  subMonths,
  subYears,
  format,
} from "date-fns";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function FollowupsReport() {
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0];
  const [fromDate, setFromDate] = useState(today);
  const [selectedOption, setSelectedOption] = useState("");
  const [toDate, setToDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [followupsData, setFollowupsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const userDetails = sessionStorage.getItem("userDetails");
    if (!userDetails) {
      navigate("/");
    }
  }, [navigate]);

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    calculateFromDate(selectedValue);
  };

  const calculateFromDate = (option) => {
    const today = new Date();
    let newFromDate;

    switch (option) {
      case "This Week":
        newFromDate = startOfWeek(today, { weekStartsOn: 1 }); 
        break;
      case "This Month":
        newFromDate = startOfMonth(today);
        break;
      case "Last 3 Months":
        newFromDate = subMonths(today, 3);
        break;
      case "1 Year":
        newFromDate = subYears(today, 1);
        break;
      default:
        newFromDate = "";
        break;
    }

    setFromDate(newFromDate);
  };

  const handleSubmit = () => {
    if (fromDate) {
      const formattedDate = format(fromDate, "yyyy-MM-dd");
      const fetchfollowupsData = async () => {
        setLoading(true);
        try {
          const response = await fetch(
            `${API_BASE_CRM_URL}reports/GetFollowupsReport?fromDate=${formattedDate}&toDate=${toDate}`
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();

          // Ensure data is an array
          if (data.Status) {
            setFollowupsData(data.ResultData);
            console.log(data.ResultData, "datatdata");
          } else {
            setFollowupsData([]);
            console.error("Unexpected response format:", data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchfollowupsData();
    } else {
      alert("Please Choose From Date");
    }
  };

  const filteredItems = followupsData.filter(
    (item) =>
      item.relType.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.FollowupType.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.Description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.FollowupStatus.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const itemsPerPage = 10;

  // Calculate indices for slicing data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const handleExcelDownload = () => {
    if (followupsData.length === 0) {
      return; // Don't download if there is no data
    }

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert your data to worksheet format
    const wsData = followupsData.map((item) => ({
      Type: item.relType,
      Title: item.Title,
      Date: formatDate(item.Date),
      "Follow By": item.FollowupType,
      Status: item.FollowupStatus,
    }));

    const ws = XLSX.utils.json_to_sheet(wsData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "followupsData");

    // Save the workbook to a file
    XLSX.writeFile(wb, "followupsReport.xlsx");
  };

  const handlePDFDownload = () => {
    const doc = new jsPDF();

    // Set properties for PDF
    doc.setProperties({
      title: "Followup's",
      author: "Your Name",
      creator: "Your Application",
    });

    // Add data to PDF
    const tableData = []; // Your data array here

    // Add a header row
    const headers = ["Type", "Title", "Date", "Follow By", "Status"];
    tableData.push(headers);

    // Add rows
    followupsData.forEach((item) => {
      const row = [];
      row.push(
        item.relType,
        item.Title,
        formatDate(item.Date),
        item.FollowupType,
        item.FollowupStatus
      );
      tableData.push(row);
    });

    // Generate table
    doc.autoTable({
      head: [headers],
      body: tableData.slice(1),
    });
    // Save the PDF
    doc.save("followupsReport.pdf");
  };

  const handleReload = () => {
    window.location.reload();
  };

  useEffect(() => {
    const today = new Date();
    const nextWeek = new Date();
    nextWeek.setDate(today.getDate() + 7);
    const formattedDate = nextWeek.toISOString().split('T')[0];
    setToDate(formattedDate);
  }, []);

  return (
    <Base>
      <div className="page-header">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="page-title">
            <h4>FollowUps Reports</h4>
            <h6>Manage your FollowUps Reports</h6>
          </div>
        </div>
        <div className="d-flex flex-wrap align-items-center mt-3">
          <div className="flex-fill d-md-flex justify-content-between align-items-center">
            <div className="input-group mb-2 mb-md-0">
              <input
                type="text"
                name="search"
                placeholder="Search..."
                className="form-control"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className="btn btn-primary" type="button">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
          <ul className="table-top-head d-flex list-unstyled mb-0 flex-wrap">
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Pdf"
                onClick={handlePDFDownload}
              >
                <img src="assets/img/icons/pdf.svg" alt="PDF" />
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Excel"
                onClick={handleExcelDownload}
              >
                <img src="assets/img/icons/excel.svg" alt="Excel" />
              </a>
            </li>
            {/* <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print">
                    <i className="fa-solid fa-print fs-5"></i>
                </a>
            </li> */}
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Refresh"
                onClick={handleReload}
              >
                <i className="fa-solid fa-arrow-rotate-left fs-5"></i>
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Collapse"
                id="collapse-header"
              >
                <i className="fa-solid fa-chevron-up fs-6"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-3 col-xl-3 col-lg-3">
          <label className="form-label">From Date</label>
          <input
            type="date"
            className="form-control"
            value={fromDate}
            onChange={handleFromDateChange}
          />
        </div>
        <div className="col-md-3 col-xl-3 col-lg-3">
          <label className="form-label">To Date</label>
          <input
            type="date"
            className="form-control"
            value={toDate}
            onChange={handleToDateChange}
          />
        </div>
        <div className="col-md-3 col-xl-3 col-lg-3">
          <label className="form-label">Report Period</label>
          <select
            className="form-select"
            onChange={handleSelectChange}
            value={selectedOption}
          >
            <option disabled value="">
              Choose
            </option>
            <option value="This Week">This Week</option>
            <option value="This Month">This Month</option>
            <option value="Last 3 Months">Last 3 Months</option>
            <option value="1 Year">1 Year</option>
          </select>
        </div>
        <div className="col-md-3 col-xl-3 col-lg-3 rounded mt-4">
          <button className="btn btn-primary" onClick={handleSubmit}>
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>

      <div className="card table-list-card">
        <div className="card-body">
          <div className="table-responsve ">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Type</th>
                  <th>Title</th>
                  <th>Date</th>
                  <th>Follow By</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <td colSpan={12} className="text-center">
                    <div className="loader m-auto my-5"></div>
                  </td>
                ) : (
                  currentItems &&
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.relType}</td>
                      <td>{item.Title}</td>
                      <td>{formatDate(item.Date)}</td>
                      <td>{item.FollowupType}</td>
                      <td className="text-primary">{item.FollowupStatus}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={followupsData.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </Base>
  );
}
