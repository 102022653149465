
import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import { Link, useParams } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Select } from 'antd';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import '../../Config/swal.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

export default function ViewPayment() {

    const { PaymentId } = useParams();

    const [description, setDescription] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [paymentData, setPaymentData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetPaymentDetails?PaymentId=${PaymentId}`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setLoading(false);
                    setPaymentData(data.ResultData[0]);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setPaymentData([]);
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const formatDate = (isoString) => {
        if (!isoString) return '';
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return (
        <Base>
            
            <div className="page-header">
                <div className="add-item d-flex">
                    <div className="page-title">
                        <h4>View Payment</h4>
                        {/* <h6>Enter fields for edit invocie payment</h6> */}
                    </div>
                </div>
                <ul className="table-top-head">
                    <li>
                        <Link to='/payments' className="btn btn-added"><i className="fa-solid fa-arrow-left me-1"></i>Go to List</Link>
                    </li>
                </ul>
            </div>
            
            <div className="d-flex">
    <div  style={{ width: '220px', background: '#f8f9fa', padding: '15px' }}>
        <h5>Sidebar</h5>
        <ul className="list-unstyled">
            <li><a href="#link1">Link 1</a></li>
            <li><a href="#link2">Link 2</a></li>
            <li><a href="#link3">Link 3</a></li>
            {/* Add more links as needed */}
        </ul>
    </div>
    <div className="card table-list-card flex-grow-1">
        <div className="card-body">
            {loading ? (
                <p className="text-center my-5">
                    <div className="containers m-auto">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                    </div>
                </p>
            ) : (
                <form>
                    <div className="row justify-content-between m-2">
                        <div className="col-md-4 col-lg-4">
                            <div className="d-flex flex-column col-12 mb-2">
                                <label className="form-label">Amount</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter received amount"
                                    value={paymentData?.amount}
                                    readOnly
                                />
                            </div>
                            <div className="d-flex flex-column col-12 mb-2">
                                <label className="form-label">Payment Mode</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={paymentData?.paymentsModeName}
                                    readOnly
                                />
                            </div>
                            <div className="d-flex flex-column col-12 mb-2">
                                <label className="form-label">Date</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formatDate(paymentData?.paymentdate)}
                                    readOnly
                                />
                            </div>
                            <div className="d-flex flex-column col-12 mb-2">
                                <label className="form-label">Transaction ID</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter transaction id"
                                    value={paymentData?.transactionid}
                                    readOnly
                                />
                            </div>
                            <div className="d-flex flex-column col-12">
                                <label className="form-label">Description</label>
                                <textarea
                                    className="form-control"
                                    value={paymentData?.note}
                                    placeholder="Enter notes..."
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-7">
                            <h3 className="text-center">Receipt</h3>
                            <div className="row mt-3">
                                <div className="col-md-6 col-lg-6 text-start">
                                    <p className="text-info fw-bold">{paymentData?.company}</p>
                                    <p>{paymentData?.city}</p>
                                    <p>{paymentData?.phonenumber}</p>
                                </div>
                                <div className="col-md-6 col-lg-6 text-end">
                                    <p className="text-primary fw-bold">{paymentData?.deleted_customer_name}</p>
                                    <p>{paymentData?.state}</p>
                                    <p>{paymentData?.zip}</p>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>Payment Date:</td>
                                            <td>{paymentData?.paymentdate}</td>
                                        </tr>
                                        <tr>
                                            <td>Payment Mode:</td>
                                            <td>{paymentData?.paymentsModeName}</td>
                                        </tr>
                                        <tr>
                                            <td>Transaction Id</td>
                                            <td>{paymentData?.transactionid}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-12 my-3 text-center">
                                <h5>Total Amount:</h5>
                            </div>
                            <div className="table-responsive">
                                <h4>Payment For:</h4>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Invoice No</th>
                                            <th>Invoice Date</th>
                                            <th>Invoice Amount</th>
                                            <th>Payment Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{paymentData?.prefix}{paymentData?.invoiceNumber}</td>
                                            <td>{formatDate(paymentData?.date)}</td>
                                            <td>{paymentData?.total}</td>
                                            <td>{paymentData?.amount}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </div>
    </div>
</div>

        </Base>
    )
}