import React, { createContext, useState, useContext } from "react";


const ContextPage = createContext();


export const ContextProvider = ({ children }) => {
  const [ID, setID] = useState(null);

  return (
    <ContextPage.Provider value={{ ID, setID }}>
      {children}
    </ContextPage.Provider>
  );
};

// Custom hook to use the context
export const useLabelContext = () => {
  return useContext(ContextPage);
};
