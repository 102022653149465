import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Base from "../../Config/Base";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Link } from "react-router-dom";
import { useLabelContext } from "../../../Context";
import AddContact from "./AddContact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faContactCard, faFileInvoice, faIdBadge, faMoneyBill, faNetworkWired, faRunning, faSackDollar, faUser } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../Config/Pagination";
import FollowUps from "../FollowUps/FollowUps";
import SetReminder from "./SetReminder";
import FollowUp from "./FollowUp";

export default function ViewCustomer() {
  //   const { cusid } = useParams();
  const { ID } = useLabelContext();
  const cusid = ID;
  console.log(cusid);
  const [activeTab, setActiveTab] = useState('view');
  const [customersData, setCustomerData] = useState([]);
  const [RemainderData, setRemainderData] = useState([]);
  const [followupData, setFollowupData] = useState([]);
  const [expenesData, setExpensesData] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [creditnoteData, setcreditnoteData] = useState([]);
  const [addContactOBJ, setAddContactOBJ] = useState(null);
  const [remidnerCusOBJ, setremidnerCusOBJ] = useState([]);
  const [followupCusOBJ, setfollowupCusOBJ] = useState([]);
  const [customerContactsData, setCustomerContactsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [projectsData, setProjectsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [RemcurrentPage, setRemCurrentPage] = useState(1);
  const RemitemsPerPage = 10;

  const [FolcurrentPage, setFolCurrentPage] = useState(1);
  const FolitemsPerPage = 10;
  const [CreditcurrentPage, setCreditCurrentPage] = useState(1);
  const CredititemsPerPage = 10;
  const [projectcurrentPage, setprojectCurrentPage] = useState(1);
  const projectitemsPerPage = 10;
  const [ExcurrentPage, setExCurrentPage] = useState(1);
  const ExtemsPerPage = 10;
  const [TracurrentPage, setTraCurrentPage] = useState(1);
  const TratemsPerPage = 10;



  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = customerContactsData.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  console.log(currentItems);

  const TraindexOfLastItem = TracurrentPage * TratemsPerPage;


  const TraindexOfFirstItem = TraindexOfLastItem - TratemsPerPage;
  const TracurrentItems = transactionData.slice(TraindexOfFirstItem, TraindexOfLastItem);
  const Trapaginate = (pageNumber) => setTraCurrentPage(pageNumber);


  const ExindexOfLastItem = ExcurrentPage * ExtemsPerPage;
  const ExindexOfFirstItem = ExindexOfLastItem - ExtemsPerPage;
  const ExcurrentItems = expenesData.slice(ExindexOfFirstItem, ExindexOfLastItem);
  const Expaginate = (pageNumber) => setExCurrentPage(pageNumber);


  const projectindexOfLastItem = projectcurrentPage * projectitemsPerPage;
  const projectindexOfFirstItem = projectindexOfLastItem - projectitemsPerPage;
  const projectcurrentItems = projectsData.slice(projectindexOfFirstItem, projectindexOfLastItem);
  const projectpaginate = (pageNumber) => setprojectCurrentPage(pageNumber);


  const CreditindexOfLastItem = CreditcurrentPage * CredititemsPerPage;
  const CreditindexOfFirstItem = CreditindexOfLastItem - CredititemsPerPage;
  const CreditcurrentItems = creditnoteData.slice(CreditindexOfFirstItem, CreditindexOfLastItem);


  const Creditpaginate = (pageNumber) => setCreditCurrentPage(pageNumber);

  const FolindexOfLastItem = FolcurrentPage * FolitemsPerPage;
  const FolindexOfFirstItem = FolindexOfLastItem - FolitemsPerPage;
  const FolcurrentItems = followupData.slice(FolindexOfFirstItem, FolindexOfLastItem);


  const Folpaginate = (pageNumber) => setFolCurrentPage(pageNumber);



  const RemindexOfLastItem = RemcurrentPage * RemitemsPerPage;
  const RemindexOfFirstItem = RemindexOfLastItem - RemitemsPerPage;
  const RemcurrentItems = RemainderData.slice(RemindexOfFirstItem, RemindexOfLastItem);


  const Rempaginate = (pageNumber) => setRemCurrentPage(pageNumber);


  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (cusid) {
      sessionStorage.setItem("purchaseId", JSON.stringify(cusid));
    }

    const storedPurchaseId = sessionStorage.getItem("purchaseId");
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${API_BASE_CRM_URL}customers/GetCustomerDetails?CusId=${storedPurchaseId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.Status) {
          setCustomerData(data.ResultData[0]);
          console.log(data.ResultData)
        } else {
          console.error("Fetched data is not an array:", data);
          setCustomerData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [cusid]);

  const showReminderModal = (obj) => {
    setremidnerCusOBJ(obj);
  };

  const showFollowupModal = (obj) => {
    setfollowupCusOBJ(obj);
  };

  const showAddContactModal = (obj) => {
    console.log("Selected Customer:", obj);
    setAddContactOBJ(obj);
  };

  const handleAddExpenses = (customerId) => {

    navigate('/add-expense', { state: { customerId } });
  };

  const handleAddTractions = (customerId) => {
    navigate('/add-invoice', { state: { customerId } });
  }

  const handleAddCreditnote = (customerId) => {
    navigate('/add-credit-notes', { state: { customerId } });
  }

  //expenses
  useEffect(() => {
    if (cusid) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${API_BASE_CRM_URL}expenses/GetCustomerExpenseDetails?clientId=${cusid}`);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          if (data.Status) {
            setExpensesData(data.ResultData);
          } else {
            console.error("Fetched data is not an array:", data);
            setExpensesData([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [cusid]);

  //projects
  useEffect(() => {
    if (cusid) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${API_BASE_CRM_URL}customers/GetCustomerProjectDetails?CusId=${cusid}`);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          if (data.Status) {
            setProjectsData(data.ResultData);
          } else {
            console.error("Fetched data is not an array:", data);
            setProjectsData([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [cusid]);

  //transactions
  useEffect(() => {
    if (cusid) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${API_BASE_CRM_URL}GetCustomerTransactionsData?clientId=${cusid}`);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          if (data.Status) {
            setTransactionData(data.ResultData);
            console.log(data.ResultData);
          } else {
            console.error("Fetched data is not an array:", data);
            setTransactionData([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [cusid]);

  //remainder
  useEffect(() => {
    const storedPurchaseId = sessionStorage.getItem("purchaseId");
    console.log(storedPurchaseId);
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${API_BASE_CRM_URL}GetCustomerReminderData?RelId=${storedPurchaseId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        if (data.Status) {
          setRemainderData(data.ResultData);
          console.log(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setRemainderData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [cusid]);

  //creditnote
  useEffect(() => {
    const storedPurchaseId = sessionStorage.getItem("purchaseId");
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${API_BASE_CRM_URL}GetCustomerCreditnoteData?clientId=${storedPurchaseId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        if (data.Status) {
          setcreditnoteData(data.ResultData);
          console.log(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setcreditnoteData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [cusid]);

  //followup
  useEffect(() => {
    const storedPurchaseId = sessionStorage.getItem("purchaseId");
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${API_BASE_CRM_URL}GetCustomerFollowupData?RelId=${storedPurchaseId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        if (data.Status) {
          setFollowupData(data.ResultData);
          console.log(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setFollowupData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [cusid]);

  //contacts
  useEffect(() => {
    const storedPurchaseId = sessionStorage.getItem("purchaseId");
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${API_BASE_CRM_URL}GetCustomerContacts?CusId=${storedPurchaseId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        if (data.Status) {
          setCustomerContactsData(data.ResultData);
          console.log(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setCustomerContactsData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [cusid]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };


  return (
    <Base>
      <div className="page-heade mb-3">
        <div className="row align-items-center mb-4">
          <div className="col-md-6">
            <div className="page-title">
              <h3 className="text-primary">#
                {customersData.vat} Info
              </h3>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-md-end justify-content-start">
            <Link to="/customers">
              <button
                className="btn btn-primary d-flex align-items-center"
                style={{ borderRadius: '8px', padding: '8px 16px', fontWeight: '500' }}
              >
                <i className="fa-solid fa-arrow-left me-2" style={{ fontSize: '1.1rem' }}></i>
                Go to List
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="d-flex">
        <div
          style={{
            width: '150px',
            background: '#f8f9fa',
            padding: '15px',
            transition: 'width 0.3s ease', // Smooth width transition
            // Adjust width and padding on smaller screens
            ...(window.innerWidth <= 768 ? { width: '100%', padding: '10px' } : {}),
          }}
        >
          <ul
            className="list-unstyled"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: window.innerWidth <= 768 ? 'center' : 'flex-start',
              padding: 0,
              margin: 0,
            }}
          >
            <li style={{ marginBottom: '15px' }}>
              <a
                onClick={() => handleTabClick('view')}
                style={{
                  fontSize: '1.0rem',
                  textDecoration: 'none',
                  color: '#333',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon
                  icon={faUser}
                  className="me-2"
                /> Profile
              </a>
            </li>
            <li style={{ marginBottom: '15px' }}>
              <a
                onClick={() => handleTabClick('contacts')}
                style={{
                  fontSize: '1.0rem',
                  textDecoration: 'none',
                  color: '#333',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon
                  icon={faIdBadge}
                  className="me-2"
                /> Contacts
              </a>
            </li>
            <li style={{ marginBottom: '15px' }}>
              <a
                onClick={() => handleTabClick('creditnote')}
                style={{
                  fontSize: '1.0rem',
                  textDecoration: 'none',
                  color: '#333',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon
                  icon={faMoneyBill}
                  className="me-2"
                /> CreditNote
              </a>
            </li>
            <li style={{ marginBottom: '15px' }}>
              <a
                onClick={() => handleTabClick('expenses')}
                style={{
                  fontSize: '1.0rem',
                  textDecoration: 'none',
                  color: '#333',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon
                  icon={faSackDollar}
                  className="me-2"
                /> Expenses
              </a>
            </li>

            <li style={{ marginBottom: '15px' }}>
              <a
                onClick={() => handleTabClick('Remainder')}
                style={{
                  fontSize: '1.0rem',
                  textDecoration: 'none',
                  color: '#333',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon
                  icon={faRunning}
                  className="me-2"
                /> Remainder
              </a>
            </li>
            <li style={{ marginBottom: '15px' }}>
              <a
                onClick={() => handleTabClick('followup')}
                style={{
                  fontSize: '1.0rem',
                  textDecoration: 'none',
                  color: '#333',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon
                  icon={faRunning}
                  className="me-2"
                /> followup
              </a>
            </li>
            <li style={{ marginBottom: '15px' }}>
              <a
                onClick={() => handleTabClick('transaction')}
                style={{
                  fontSize: '1.0rem',
                  textDecoration: 'none',
                  color: '#333',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon
                  icon={faFileInvoice}
                  className="me-2"
                /> Transaction
              </a>
            </li>


            <li style={{ marginBottom: '15px' }}>
              <a
                onClick={() => handleTabClick('projects')}
                style={{
                  fontSize: '1.0rem',
                  textDecoration: 'none',
                  color: '#333',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon
                  icon={faNetworkWired}
                  className="me-2"
                /> Projects
              </a>
            </li>

          </ul>
        </div>

        <div className="col-xxl-10 col-xl-10">
          {activeTab === 'view' && (
            <div className="customer-details-summary m-3 p-2 ">
              <h4>Profile</h4>
            </div>
          )}
          {activeTab === 'contacts' && (
            <div className="customer-details-summary m-3 p-2">
              <div className="d-flex align-items-center justify-content-between">
                <h4>Contact details</h4>
                <a
                  className="btn btn-primary"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasContact"
                  aria-controls="offcanvasContact"
                  onClick={() => {
                    console.log("Button Clicked");
                    console.log(customersData);
                    const selectedCustomer = customersData;
                    console.log("Button Clicked, Customer Selected:", selectedCustomer);
                    showAddContactModal(selectedCustomer);
                  }}
                >
                  <i className="fa-regular fa-address-book me-2"></i>
                  Add Contact
                </a>
              </div>
            </div>
          )}
          {activeTab === 'Remainder' && (
            <div className="customer-details-summary m-3 p-2">
              <div className="d-flex align-items-center justify-content-between">
                <h4>Remainders</h4>
                <a
                  className="btn btn-primary"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasReminder"
                  aria-controls="offcanvasReminder"
                  onClick={() => {
                    console.log("Button Clicked");
                    console.log(customersData);
                    const selectedCustomer = customersData;
                    console.log("Button Clicked, Customer Selected:", selectedCustomer);
                    showReminderModal(selectedCustomer);
                  }}
                >
                  <i className="fa-solid fa-business-time me-2 text-info"></i>
                  Set Reminder
                </a>
              </div>
            </div>
          )}

          {activeTab === 'followup' && (
            <div className="customer-details-summary m-3 p-2">
              <div className="d-flex align-items-center justify-content-between">
                <h4>Followup</h4>
                <a
                  className="btn btn-primary"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasFollowup"
                  aria-controls="offcanvasFollowup"
                  onClick={() => {
                    console.log("Button Clicked");
                    console.log(customersData);
                    const selectedCustomer = customersData;
                    console.log("Button Clicked, Customer Selected:", selectedCustomer);
                    showFollowupModal(selectedCustomer);
                  }}
                >
                  <i className="fa-solid fa-reply-all me-2"></i>
                  Follow Up
                </a>
              </div>
            </div>
          )}

          {activeTab === 'creditnote' && (
            <div className="customer-details-summary m-3 p-2 ">
              <div className="d-flex align-items-center justify-content-between">
                <h4> CrediteNote</h4>
                {Array.isArray(customersData) && customersData.length > 0 ? (
                  customersData.map((customer) => (
                    <div key={customer.id} className="d-flex align-items-center mb-2">
                      <span className="me-2">{customer.name}</span>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleAddCreditnote(customer.userid)}
                      >
                        Add Creditnote
                      </button>
                    </div>
                  ))
                ) : (
                  <p>No customers available.</p>
                )}
              </div>
            </div>
          )}
          {activeTab === 'projects' && (
            <div className="customer-details-summary m-3 p-2 ">
              <h4> Project</h4>
            </div>
          )}
          {activeTab === 'expenses' && (
            <div className="customer-details-summary m-3 p-2">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="d-flex align-items-center">Expenses</h4>

                {Array.isArray(customersData) && customersData.length > 0 ? (
                  customersData.map((customer) => (
                    <div key={customer.id} className="d-flex align-items-center mb-2">
                      <span className="me-2">{customer.name}</span>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleAddExpenses(customer.userid)}
                      >
                        Add Expenses
                      </button>
                    </div>
                  ))
                ) : (
                  <p>No customers available.</p>
                )}
              </div>
            </div>
          )}

          {activeTab === 'transaction' && (
            <div className="customer-details-summary m-3 p-2 ">
              <div className="d-flex align-items-center justify-content-between">
                <h4> Transaction</h4>
                {Array.isArray(customersData) && customersData.length > 0 ? (
                  customersData.map((customer) => (
                    <div key={customer.id} className="d-flex align-items-center mb-2">
                      <span className="me-2">{customer.name}</span>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleAddTractions(customer.userid)}
                      >
                        Transaction
                      </button>
                    </div>
                  ))
                ) : (
                  <p>No customers available.</p>
                )}
              </div>
            </div>
          )}
          <div className="card mt-0">
            <div
              className="card-body"
              style={{ maxHeight: "100vh", overflowY: "auto" }}
            >
              <div className="tab-content" id="myTabContent">

                {activeTab === 'view' && (
                  <div
                    className="tab-pane fade show active text-muted"
                    id="order-tab-pane"
                    role="tabpanel"
                    aria-labelledby="order-tab"
                    tabIndex="0"
                  >
                    <div className="row m-3">
                      <div className="col-md-4 col-lg-4 mb-2">
                        <label className="form-label">Property type</label>
                        <input className="form-control" value={customersData.company || ""} readOnly />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="form-label">Name</label>
                        <input className="form-control" value={customersData.vat || ""} readOnly />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="form-label">Phone Number</label>
                        <input className="form-control" value={customersData.phonenumber || ""} readOnly />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="form-label">project</label>
                        <input className="form-control" value={customersData.name || ""} readOnly />
                      </div>
                      {/* <div className="col-md-4 col-lg-4">
                        <label className="form-label">Website</label>
                        <input className="form-control" value={customersData.website || ""} readOnly />
                      </div> */}
                      <div className="col-md-4 col-lg-4 mb-2">
                        <label className="form-label">Created On</label>
                        <input className="form-control" value={customersData.datecreated || ""} readOnly />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="form-label">City</label>
                        <input className="form-control" value={customersData.city || ""} readOnly />
                      </div>
                      <div className="col-md-4 col-lg-4 mb-2">
                        <label className="form-label">State</label>
                        <input className="form-control" value={customersData.state || ""} readOnly />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="form-label">Budget</label>
                        <input className="form-control" value={customersData.zip || ""} readOnly />
                      </div>

                      <div className="col-md-4 col-lg-4">
                        <label className="form-label">Shipping Street</label>
                        <input className="form-control" value={customersData.shipping_street || ""} readOnly />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="form-label">Shipping City</label>
                        <input className="form-control" value={customersData.shipping_city || ""} readOnly />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="form-label">Shipping State</label>
                        <input className="form-control" value={customersData.shipping_state || ""} readOnly />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="form-label">Shipping Zipcode</label>
                        <input className="form-control" value={customersData.shipping_zip || ""} readOnly />
                      </div>

                      <div className="col-md-4 col-lg-4">
                        <label className="form-label">Billing Street</label>
                        <input className="form-control" value={customersData.billing_street || ""} readOnly />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="form-label">Billing City</label>
                        <input className="form-control" value={customersData.billing_city || ""} readOnly />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="form-label">Billing State</label>
                        <input className="form-control" value={customersData.billing_state || ""} readOnly />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="form-label">Billing Zipcode</label>
                        <input className="form-control" value={customersData.billing_zip || ""} readOnly />
                      </div>

                    </div>
                  </div>
                )}

                {/* {activeTab === 'confirm' && (
                  <div
                    className="tab-pane fade text-muted"
                    id="confirm-tab-pane"
                    role="tabpanel"
                    aria-labelledby="confirmed-tab"
                    tabIndex="0"
                  >
                    <div className="row m-3">
                      <div className="col-md-4 col-lg-4">
                        <label className="form-label">Billing Street</label>
                        <input className="form-control" value={customersData.billing_street || ""} readOnly />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="form-label">Billing City</label>
                        <input className="form-control" value={customersData.billing_city || ""} readOnly />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="form-label">Billing State</label>
                        <input className="form-control" value={customersData.billing_state || ""} readOnly />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="form-label">Billing Zipcode</label>
                        <input className="form-control" value={customersData.billing_zip || ""} readOnly />
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === 'shipped' && (
                  <div
                    className="tab-pane fade text-muted"
                    id="shipped-tab-pane"
                    role="tabpanel"
                    aria-labelledby="shipped-tab"
                    tabIndex="0"
                  >
                    <div className="row m-3">
                      <div className="col-md-4 col-lg-4">
                        <label className="form-label">Shipping Street</label>
                        <input className="form-control" value={customersData.shipping_street || ""} readOnly />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="form-label">Shipping City</label>
                        <input className="form-control" value={customersData.shipping_city || ""} readOnly />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="form-label">Shipping State</label>
                        <input className="form-control" value={customersData.shipping_state || ""} readOnly />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="form-label">Shipping Zipcode</label>
                        <input className="form-control" value={customersData.shipping_zip || ""} readOnly />
                      </div>
                    </div>
                  </div>
                )} */}
              </div>

              {activeTab === 'contacts' && (
                <div
                  className="tab-pane fade show active text-muted"
                  id="contacts-tab-pane"
                  role="tabpanel"
                  aria-labelledby="contacts-tab"
                  tabIndex="0"
                >
                  <div className="row m-3">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>

                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone No</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems &&
                            currentItems.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.firstname + ' ' + item.lastname}</td>
                                <td>{item.email}</td>
                                <td>{item.phonenumber}</td>
                                <td>
                                  {item.active === 1 ? (
                                    <span className="badge bg-outline-success">Active</span>
                                  ) : (
                                    <span className="badge bg-outline-danger">Inactive</span>
                                  )}
                                </td>
                                <td>
                                  <a
                                    className={`dropdown-item ${item.active ? '' : 'disabled'}`}
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasContact"
                                    aria-controls="offcanvasContact"
                                    onClick={() => showAddContactModal(item)}
                                  >
                                    <i className="fa-regular fa-address-book me-2 text-success"></i>
                                    Add Contact
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>

                      </table>
                      <Pagination
                        itemsPerPage={itemsPerPage}
                        totalItems={customerContactsData.length}
                        paginate={paginate}
                        currentPage={currentPage}
                      />
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'projects' && (
                <div
                  className="tab-pane fade show active text-muted"
                  id="project-tab-pane"
                  role="tabpanel"
                  aria-labelledby="project-tab"
                  tabIndex="0"
                >
                  <div className="row m-3">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Project</th>
                            <th>cost</th>
                            <th>Description</th>
                            <th>Start Date</th>
                            <th>Deadline</th>
                          </tr>
                        </thead>
                        <tbody>
                          {projectcurrentItems &&
                            projectcurrentItems.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.zip}</td>
                                <td>{item.description}</td>
                                <td>{formatDate(item.start_date)}</td>
                                <td>{formatDate(item.deadline)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'Remainder' && (
                <div
                  className="tab-pane fade show active text-muted"
                  id="project-tab-pane"
                  role="tabpanel"
                  aria-labelledby="project-tab"
                  tabIndex="0"
                >
                  <div className="row m-3">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Description</th>
                            <th>Reminder For</th>

                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <td colSpan={12} className="text-center">
                              <div className="loader m-auto my-5"></div>
                            </td>
                          ) : (
                            RemcurrentItems &&
                            RemcurrentItems.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.description}</td>
                                <td className="text-primary">{item.rel_type}</td>

                                <td>{formatDate(item.date)}</td>
                                <td
                                  className={
                                    item.is_send === 0
                                      ? "badge text-warning"
                                      : "badge text-success"
                                  }
                                >
                                  {item.is_send === 0 ? "Pending" : "Sent"}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                      <Pagination
                        itemsPerPage={itemsPerPage}
                        totalItems={RemainderData.length}
                        paginate={Rempaginate}
                        currentPage={currentPage}
                      />
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'followup' && (
                <div
                  className="tab-pane fade show active text-muted"
                  id="project-tab-pane"
                  role="tabpanel"
                  aria-labelledby="project-tab"
                  tabIndex="0"
                >
                  <div className="row m-3">
                    <div className="table-responsive">
                      <table className="table datane">
                        <thead>
                          <tr>
                            <th className="no-sort">
                              <label className="checkboxs">
                                <input type="checkbox" id="select-all" />
                                <span className="checkmarks"></span>
                              </label>
                            </th>
                            <th>Type</th>
                            <th>Title</th>
                            <th>Related to</th>
                            <th>Followup By</th>
                            <th>Date</th>
                            <th>Status</th>

                          </tr>
                        </thead>
                        <tbody>
                          {loading ?
                            <td colSpan={12} className="etxt-center">
                              <p className="text-center">
                                <div class="containers m-auto">
                                  <div class="dot"></div>
                                  <div class="dot"></div>
                                  <div class="dot"></div>
                                  <div class="dot"></div>
                                  <div class="dot"></div>
                                  <div class="dot"></div>
                                </div>
                              </p>
                            </td> :
                            FolcurrentItems && FolcurrentItems.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks"></span>
                                  </label>
                                </td>
                                <td>{item.relType}</td>
                                <td>{item.Title}</td>
                                <td>{item.RelatedName}</td>
                                <td>{item.FollowupType}</td>
                                <td>{formatDate(item.Date)}</td>
                                <td>
                                  {item.IsActive === 1 ? (
                                    <span className="badge bg-outline-success">Active</span>
                                  ) : (
                                    <span className="badge bg-outline-danger">Inactive</span>
                                  )}
                                </td>

                              </tr>
                            ))}
                        </tbody>
                      </table>
                      <Pagination
                        itemsPerPage={FolitemsPerPage}
                        totalItems={followupData.length}
                        paginate={Folpaginate}
                        currentPage={currentPage}
                      />
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'creditnote' && (
                <div
                  className="tab-pane fade show active text-muted"
                  id="project-tab-pane"
                  role="tabpanel"
                  aria-labelledby="project-tab"
                  tabIndex="0"
                >
                  <div className="row m-3">
                    <div className="table-responsive">
                      <table className="table dataew">
                        <thead>
                          <tr>
                            <th className="no-sort">#</th>
                            <th className="no-sort text-start">Action</th>

                            <th>Title</th>
                            <th>Customer</th>
                            <th>Total Tax</th>
                            <th>Date</th>
                            <th>Expiry Date</th>
                            {/* <th>Status</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <td colSpan={12} className="etxt-center">
                              <p className="text-center">
                                <div className="containers m-auto">
                                  <div className="dot"></div>
                                  <div className="dot"></div>
                                  <div className="dot"></div>
                                  <div className="dot"></div>
                                  <div className="dot"></div>
                                  <div className="dot"></div>
                                </div>
                              </p>
                            </td>
                          ) : (
                            CreditcurrentItems &&
                            CreditcurrentItems.map((item, index) => (
                              <tr key={index}>
                                <td>{indexOfFirstItem + index + 1}</td>

                                <td>
                                  <Link to={`/view-purchase-order/${item.id}`}>
                                    CN-{item.id}
                                  </Link>
                                </td>
                                <td>{item.deleted_customer_name}</td>
                                <td>{item.customername}</td>

                                {/* <td>{item.vat}</td> */}
                                {/* <td>{item.proj_name ? item.proj_name : "N/A"}</td> */}
                                <td>{item.total_tax}</td>
                                <td>{formatDate(item.date)}</td>
                                <td>
                                  {item.expirydate ? formatDate(item.expirydate) : "N/A"}
                                </td>
                                {/* <td>
                                            {(() => {
                                                switch (item.status) {
                                                    case 1:
                                                        return <span className="badge bg-outline-warning">Draft</span>;
                                                    case 2:
                                                        return <span className="badge bg-outline-info">Sent</span>;
                                                    case 3:
                                                        return <span className="badge bg-outline-danger">Declined</span>;
                                                    case 4:
                                                        return <span className="badge bg-outline-success">Accepted</span>;
                                                    case 5:
                                                        return <span className="badge bg-outline-secondary">Expired</span>;
                                                    default:
                                                        return <span className="badge bg-outline-dark">Unknown</span>;
                                                }
                                            })()}
                                        </td> */}
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                      <Pagination
                        itemsPerPage={FolitemsPerPage}
                        totalItems={creditnoteData.length}
                        paginate={Creditpaginate}
                        currentPage={currentPage}
                      />
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'expenses' && (
                <div
                  className="tab-pane fade show active text-muted"
                  id="project-tab-pane"
                  role="tabpanel"
                  aria-labelledby="project-tab"
                  tabIndex="0"
                >
                  <div className="row m-3">
                    <div className="table-responsive">
                      <table className="table dataew">
                        <thead>
                          <tr>
                            <th className="no-sort">#</th>
                            <th>Subject</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Project</th>
                            <th>Payment Type</th>

                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <td colSpan={12} className="text-center">
                              <p className="text-center">
                                <div class="containers m-auto">
                                  <div class="dot"></div>
                                  <div class="dot"></div>
                                  <div class="dot"></div>
                                  <div class="dot"></div>
                                  <div class="dot"></div>
                                  <div class="dot"></div>
                                </div>
                              </p>
                            </td>
                          ) : (
                            ExcurrentItems &&
                            ExcurrentItems.map((item, index) => (
                              <tr key={index}>
                                <td>{ExindexOfFirstItem + index + 1}</td>
                                <td>{item.expense_name}</td>
                                <td>{formatDate(item.date)}</td>

                                <td>{item.amount}</td>

                                <td>{item.proj_name}</td>
                                <td>{item.paymentmode}</td>

                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                      <Pagination
                        itemsPerPage={itemsPerPage}
                        totalItems={expenesData.length}
                        paginate={Expaginate}
                        currentPage={currentPage}
                      />
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'transaction' && (
                <div
                  className="tab-pane fade show active text-muted"
                  id="project-tab-pane"
                  role="tabpanel"
                  aria-labelledby="project-tab"
                  tabIndex="0"
                >
                  <div className="row m-3">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th className="no-sort">#</th>
                            <th className="no-sort">Action</th>
                            {/* <th>Number</th> */}
                            <th>Agent</th>
                            <th>Amount</th>

                            <th>Date</th>
                            <th>Customer</th>
                            <th>Project</th>
                            <th>Due Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <td colSpan={12} className="text-center">
                              <p className="text-center">
                                <div class="containers m-auto">
                                  <div class="dot"></div>
                                  <div class="dot"></div>
                                  <div class="dot"></div>
                                  <div class="dot"></div>
                                  <div class="dot"></div>
                                  <div class="dot"></div>
                                </div>
                              </p>
                            </td>
                          ) : (
                            TracurrentItems &&
                            TracurrentItems.map((item, index) => (
                              <tr key={index}>
                                <td>{indexOfFirstItem + index + 1}</td>
                                <td>{item.deleted_customer_name}</td>
                                <td>{item.totalamount ? item.totalamount : "N/A"}</td>
                                <td></td>
                                <td>{formatDate(item.date)}</td>
                                <td>{item.vat ? item.vat : "N/A"}</td>
                                <td>{item.proj_name ? item.proj_name : "N/A"}</td>
                                <td>{formatDate(item.duedate)}</td>
                                <td>
                                  {item.status === 1 ? (
                                    <span className="badge badge-linesuccess ms-2">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="badge badge-linedanger ms-2">
                                      Inactive
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                      <Pagination
                        itemsPerPage={itemsPerPage}
                        totalItems={transactionData.length}
                        paginate={Trapaginate}
                        currentPage={currentPage}
                      />
                    </div>
                  </div>
                </div>
              )}


            </div>
          </div>
        </div>

      </div>

      <SetReminder cusOBJ={remidnerCusOBJ} />
      <FollowUp cusOBJ={followupCusOBJ} />
      <AddContact cusOBJ={addContactOBJ ? [addContactOBJ] : []} />
    </Base>
  );
}
