import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import { Link, useParams } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Select } from "antd";
import "../../Config/swal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { useLabelContext } from "../../../Context";

export default function ViewCreditNotes() {
  //   const { creditNotesId } = useParams();
  const { ID } = useLabelContext();
  const creditNotesId = ID;

  const [creditNoteData, SetCreditNoteData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (creditNotesId) {
      sessionStorage.setItem("purchaseId", JSON.stringify(creditNotesId));
    }

    const storedPurchaseId = sessionStorage.getItem("purchaseId");
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${API_BASE_CRM_URL}creditnotes/GetCreditNoteById?CNId=${storedPurchaseId}`
        );
        // if (!response.ok) {
        //   throw new Error("Network response was not ok");
        // }
        const data = await response.json();
        if (data.Status) {
          setLoading(false);
          SetCreditNoteData(data.ResultData);
        } else {
          setLoading(false);
          console.error("Fetched data is not an array:", data);
          SetCreditNoteData([]);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [creditNotesId]);

  const formatDate = (isoString) => {
    if (!isoString) return "";
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <Base>
      <div className="page-header">
        <div className="add-item d-flex">
          <div className="page-title">
            <h4>View Credit Notes</h4>
            {/* <h6>Enter fields for add credit note</h6> */}
          </div>
        </div>
        <ul className="table-top-head">
          <li>
            <Link to="/credit-notes" className="btn btn-added">
              <i className="fa-solid fa-arrow-left me-1"></i>Go to List
            </Link>
          </li>
        </ul>
      </div>

      <div className="card table-list-card">
        <div className="card-body">
          <form>
            <div className="row m-2">
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Customer</label>
                <input
                  type="text"
                  className="form-control"
                  value={creditNoteData?.creditNote?.cus_name}
                  readOnly
                />
              </div>
              {/* <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Project</label>
                <input
                  type="text"
                  className="form-control"
                  value={creditNoteData?.creditNote?.proj_name}
                  readOnly
                />
              </div> */}
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Discount Type</label>
                <input
                  type="text"
                  className="form-control"
                  value={creditNoteData?.creditNote?.discount_type}
                  readOnly
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Date</label>
                <input
                  type="text"
                  className="form-control"
                  value={formatDate(creditNoteData?.creditNote?.date)}
                  readOnly
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Total Tax</label>
                <input
                  type="text"
                  className="form-control"
                  value={creditNoteData?.creditNote?.total_tax}
                  readOnly
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Total Amount</label>
                <input
                  type="text"
                  className="form-control"
                  value={creditNoteData?.creditNote?.total}
                  readOnly
                />
              </div>
              <div className="d-flex justify-content-between">
                <h6 className="text-start mt-2">Products:</h6>
                <div>
                  <p
                    data-bs-toggle="modal"
                    data-bs-target="#billToModal"
                    style={{ cursor: "pointer", margin: 0 }}
                    className="text-end d-inline-block"
                  >
                    Billing
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="text-primary fs-6 btn"
                    />
                  </p>
                  <p
                    data-bs-toggle="modal"
                    data-bs-target="#shipToModal"
                    style={{ cursor: "pointer", margin: 0, marginLeft: "15px" }}
                    className="text-end d-inline-block"
                  >
                    Shipping
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="text-primary fs-6 btn"
                    />
                  </p>
                </div>
              </div>

              {/* <div className="col-12 my-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product</th>
                      <th>Quantity</th>
                      <th>Rate</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {creditNoteData?.products?.map((row, index) => (
                      <tr key={row.id}>
                        <td>{index + 1}</td>
                        <td>{row.description}</td>
                        <td>{row.qty}</td>
                        <td>{row.rate}</td>
                        <td>{row.rate * row.qty}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div> */}

              <div className="d-flex flex-column col-lg-12 col-md-12">
                <label className="form-label">Description</label>
                <textarea
                  type="text"
                  className="form-control"
                  value={creditNoteData?.creditNote?.adminnote}
                  readOnly
                />
              </div>
            </div>
          </form>

          <div
            className="modal fade"
            id="billToModal"
            tabindex="-1"
            aria-labelledby="billToModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="billToModalLabel">
                    Bill to
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <label className="form-label">Street</label>
                  <input
                    className="form-control"
                    type="text"
                    value={creditNoteData?.creditNote?.billing_street}
                    readOnly
                  />
                  <label className="form-label">City</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter City"
                    name="city"
                    value={creditNoteData?.creditNote?.billing_city}
                    readOnly
                  />
                  <label className="form-label">State</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter state"
                    name="state"
                    value={creditNoteData?.creditNote?.billing_state}
                    readOnly
                  />
                  <label className="form-label">Pincode</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter pincode"
                    name="pincode"
                    value={creditNoteData?.creditNote?.billing_zip}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="shipToModal"
            tabindex="-1"
            aria-labelledby="shipToModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="shipToModalLabel">
                    Ship to
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <label className="form-label">Street</label>
                  <input
                    className="form-control"
                    type="text"
                    value={creditNoteData?.creditNote?.shipping_street}
                    readOnly
                  />
                  <label className="form-label">City</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter City"
                    name="city"
                    value={creditNoteData?.creditNote?.shipping_city}
                    readOnly
                  />
                  <label className="form-label">State</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter state"
                    name="state"
                    value={creditNoteData?.creditNote?.shipping_state}
                    readOnly
                  />
                  <label className="form-label">Pincode</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter pincode"
                    name="pincode"
                    value={creditNoteData?.creditNote?.shipping_zip}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
}
