import React, { useState, useEffect } from 'react';
import Base from '../../Config/Base';
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import { Select } from 'antd';
import AddStatus from './AddStatus';
import AddSource from './AddSource';
import '../../Config/swal.css';


export default function AddLead() {

    const [userDetails, SetUserDetails] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    useEffect(() => {
        const userDetails = sessionStorage.getItem('userDetails');
        if (!userDetails) {
            navigate('/');
        }
    }, [navigate]);

    const [loading, setLoading] = useState(false);
    const [leadsStatusData, setLeadsStatusData] = useState([]);
    const [leadSourceData, setLeadeSourceData] = useState([]);
    const [selectedAssignee, setSelectedAssignee] = useState([])
    const [selectedSourceId, setSelectedSourceId] = useState('');
    const [selectedStatusId, setSelectedStatusId] = useState('');
    const [listingData, setListingData] = useState([]);
    const [selectedlistingsId, setSelectedlistingsId] = useState([]);

    const [formValues, setFormValues] = useState({
        Name: '',
        Title: '',
        Company: '',
        Description: '',
        Country: 1,
        City: '',
        Zip: '',
        State: '',
        Address: '',
        Source: selectedSourceId ? selectedSourceId : '',
        LastContact: '',
        AddedFrom: userDetails.staffid,
        assigned: '',
        Email: '',
        Website: '',
        PhoneNumber: '',
        Status: selectedStatusId
    });

    const [assigneeData, setAssigneeData] = useState("")

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetStaff`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setAssigneeData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setAssigneeData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const AssigneeOptions = assigneeData && assigneeData.map((item) => ({
        value: item.staffid,
        label: item.firstname + " " + item.lastname,
    }));

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_CRM_URL}leads/GetLeadSources`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();

                if (data.Status) {
                    setLeadeSourceData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setLeadeSourceData([]); // Set an empty array to avoid errors
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_CRM_URL}leads/GetLeadStatus`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();

                if (data.Status) {
                    setLeadsStatusData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    // useEffect(() => {
    //     const fetchData = async () => {
    //       setLoading(true);
    //       try {
    //             const response = await fetch(`${API_BASE_CRM_URL}listing`);
    //             if (!response.ok) {
    //                 throw new Error("Network response was not ok");
    //             }
    //             const data = await response.json();

    //             if (data.Status) {
    //                 setListingData(data.ResultData);
    //                 console.log(data.ResultData);
    //             } else {
    //                 console.error("Fetched data is not an array:", data);
    //             }
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     fetchData();
    // }, []);

    const sourceOptions = leadSourceData && leadSourceData.map((item) => ({
        value: item.SourceId,
        label: item.SourceName,
    }));

    const statusOptions = leadsStatusData && leadsStatusData.map((item) => ({
        value: item.id,
        label: item.name,
    }));

    // const listingOptions =  listingData && listingData.map((item) => ({
    //     value: item.id,
    //     label: item.name,
    // }));

    // console.log(listingOptions);

    const handleSourceSelectChange = (selectedOption) => {
        setSelectedSourceId(selectedOption);
    };

    const handleStatusSelectChange = (selectedOption) => {
        setSelectedStatusId(selectedOption);
    };

    // const handleListingSelectChange = (selectedOption) => {
    //     setSelectedlistingsId(selectedOption.label);
    //     console.log(selectedOption.label);
    // };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        formValues.Source = selectedSourceId;
        formValues.Status = selectedStatusId ? selectedStatusId : 3;
        formValues.AddedFrom = userDetails.staffid;
        formValues.assigned = selectedAssignee;
        try {
            const response = await fetch(`${API_BASE_CRM_URL}leads/AddLead`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formValues),
            });

            const result = await response.json();
            console.log(result, 'resultttt')

            if (result.Status) {
                setLoading(false);
                Swal.fire({
                    title: `Lead Add Successfully.`,
                    icon: "success",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        // window.location.reload();
                        navigate('/leads');
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Failed to submit request.",
                    icon: "error",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                });
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleAssigneeChange = (selectedone) => {
        setSelectedAssignee(selectedone);
    };

    return (
        <Base>
            <div className="page-heade mb-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="page-title">
                        <h4>Add Lead</h4>
                        <p className='fw-semibold'>Fill out the form to add a new lead</p>
                    </div>

                    <div className=''>
                        <Link to='/leads'>
                            <button className="btn btn-primary" style={{ borderRadius: '10px' }}>
                                <i className="fa-solid fa-arrow-left me-2"></i> Go to List
                            </button>
                        </Link>
                    </div>
                </div>
            </div>


            <div className='row'>

                <div className="col-xxl-12 col-xl-12">
                    <div className="card">
                        <div className="card-body" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                            <form onSubmit={handleSubmit}>
                                <div className='row m-3'>
                                    <h5 className='mb-3'>Leads Information:</h5>
                                    <div className='col-md-4 col-lg-4 mb-2'>
                                        <label className='form-label'>Propert Type<span className='text-danger'>*</span></label>
                                        <input
                                            className='form-control'
                                            type='text'
                                            name='Company'
                                            value={formValues.Company}
                                            onChange={handleChange}
                                            placeholder='Enter property type'
                                            required
                                        />
                                    </div>
                                    <div className='col-md-4 col-lg-4 mb-2 d-flex flex-column'>
                                        <label className='form-label'>
                                            Source<span className='text-danger'>*</span>
                                        </label>
                                        <div className='d-flex'>
                                            <Select
                                                showSearch
                                                placeholder="Choose source"
                                                value={selectedSourceId || null}
                                                onChange={handleSourceSelectChange}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={sourceOptions}
                                                style={{ flexGrow: 1, height: '2.4rem' }}
                                            />
                                            <button
                                                type='button'
                                                className='btn btn-dark text-light ms-2'
                                                data-bs-toggle="modal"
                                                data-bs-target="#add-source"
                                            >
                                                <i className='fa fa-plus'></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-lg-4 mb-2 d-flex flex-column'>
                                        <label className='form-label'>
                                            Status<span className='text-danger'>*</span>
                                        </label>
                                        <div className='d-flex'>
                                            <Select
                                                showSearch
                                                placeholder="Choose status"
                                                value={selectedStatusId || null}
                                                onChange={handleStatusSelectChange}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={statusOptions}
                                                style={{ flexGrow: 1, height: '2.4rem' }}
                                            />
                                            <button
                                                type='button'
                                                className='btn btn-dark text-light ms-2'
                                                data-bs-toggle="modal"
                                                data-bs-target="#add-status"
                                            >
                                                <i className='fa fa-plus'></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                        <label className="form-label">Assignees</label>
                                        <Select
                                            showSearch
                                            placeholder="Select Assignee"
                                            value={selectedAssignee || null}
                                            onChange={handleAssigneeChange}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={AssigneeOptions}
                                            style={{ height: '2.4rem' }}
                                            mode="multiple"
                                        />
                                    </div>
                                    <div className='col-md-4 col-lg-4 mb-2'>
                                        <label className='form-label'> preferred Address<span className='text-danger'>*</span></label>
                                        <input
                                            className='form-control'
                                            type='text'
                                            name='Address'
                                            value={formValues.Address}
                                            onChange={handleChange}
                                            placeholder='Enter address'
                                            required
                                        />
                                    </div>
                                    <div className='col-md-4 col-lg-4 mb-2'>
                                        <label className='form-label'> preferred City</label>
                                        <input
                                            className='form-control'
                                            type='text'
                                            name='City'
                                            value={formValues.City}
                                            onChange={handleChange}
                                            placeholder='Enter city'
                                        />
                                    </div>
                                    <div className='col-md-4 col-lg-4 mb-2'>
                                        <label className='form-label'>Budget</label>
                                        <input
                                            className='form-control'
                                            type='text'
                                            name='Zip'
                                            value={formValues.Zip}
                                            onChange={handleChange}
                                            placeholder='Enter Budget'
                                        />
                                    </div>


                                    <h5 className='my-3'>Lead Person Details:</h5>
                                    <div className='col-md-4 col-lg-4 mb-2'>
                                        <label className='form-label'>Name<span className='text-danger'>*</span></label>
                                        <input
                                            className='form-control'
                                            type='text'
                                            name='Name'
                                            value={formValues.Name}
                                            onChange={handleChange}
                                            placeholder='Enter name'
                                            required
                                        />
                                    </div>
                                    {/* <div className='col-md-4 col-lg-4 mb-2'>
                                        <label className='form-label'>Title</label>
                                        <input
                                            className='form-control'
                                            type='text'
                                            name='Title'
                                            value={formValues.Title}
                                            onChange={handleChange}
                                            placeholder='Enter title'
                                        />
                                    </div> */}

                                    <div className='col-md-4 col-lg-4 mb-2'>
                                        <label className='form-label'>Phone Number<span className='text-danger'>*</span></label>
                                        <input
                                            className='form-control'
                                            type='text'
                                            name='PhoneNumber'
                                            value={formValues.PhoneNumber}
                                            onChange={handleChange}
                                            placeholder='Enter mobile no'
                                            required
                                        />
                                    </div>
                                    <div className='col-md-4 col-lg-4 mb-2'>
                                        <label className='form-label'>Email</label>
                                        <input
                                            className='form-control'
                                            type='email'
                                            name='Email'
                                            value={formValues.Email}
                                            onChange={handleChange}
                                            placeholder='Enter email'
                                        // required
                                        />
                                    </div>
                                    {/* <div className='col-md-4 col-lg-4 mb-2'>
                                        <label className='form-label'>Website</label>
                                        <input
                                            className='form-control'
                                            type='text'
                                            name='Website'
                                            value={formValues.Website}
                                            onChange={handleChange}
                                            placeholder='Enter webiste'
                                        />
                                    </div> */}
                                    {/* <div className='col-md-4 col-lg-4 mb-2'>
                                        <label className='form-label'>State</label>
                                        <input
                                            className='form-control'
                                            type='text'
                                            name='State'
                                            value={formValues.State}
                                            onChange={handleChange}
                                            placeholder='Enter state'
                                        />
                                    </div> */}
                                </div>
                                <div className='row'>
                                    <button type="submit" className="btn btn-primary d-flex m-auto col-1" disabled={loading}>
                                        {loading ? 'Adding...' : 'Add Lead'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <AddStatus />
            <AddSource />
        </Base>
    );
}
