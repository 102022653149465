import React, { useState, useEffect, useMemo } from "react";
import Base from "../../Config/Base";
import Pagination from "../../Config/Pagination";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Link, useNavigate } from "react-router-dom";
import "../../Config/search.css";
import AddContact from "./AddContact";
import SetReminder from "./SetReminder";
import FollowUp from "./FollowUp";
import "../../Config/loader.css";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "../../Config/loading.css";
import { useLabelContext } from "../../../Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";

export default function Leads() {
  const navigate = useNavigate();

  const [leadsData, setLeadsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [contactLeadOBJ, setContactLeadOBJ] = useState([]);
  const [remidnerLeadOBJ, setRemidnerLeadOBJ] = useState([]);
  const [followupLeadOBJ, setFollowupLeadOBJ] = useState([]);
  const { setID } = useLabelContext();

  useEffect(() => {
    const userDetails = sessionStorage.getItem("userDetails");
    if (!userDetails) {
      navigate("/");
    }
  }, [navigate]);



  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_CRM_URL}leads/GetLeads`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        if (data.Status) {
          setLeadsData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setLeadsData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    // Format as dd-mm-yyyy
    return `${day}-${month}-${year}`;
  };

  const showContactModal = (obj) => {
    setContactLeadOBJ(obj);
  };

  const showReminderModal = (obj) => {
    setRemidnerLeadOBJ(obj);
  };

  const showFollowupModal = (obj) => {
    setFollowupLeadOBJ(obj);
  };

  const itemsPerPage = 10;

  const filteredItems = useMemo(() => {
    const results = leadsData.filter(
      (item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.phonenumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (item.source_name &&
          item.source_name.toLowerCase().includes(searchTerm.toLowerCase()))
    );


    setCurrentPage(1);

    return results;
  }, [leadsData, searchTerm]);

  // Calculate indices for slicing data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleExcelDownload = () => {
    if (leadsData.length === 0) {
      return; // Don't download if there is no data
    }

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert your data to worksheet format
    const wsData = leadsData.map((item) => ({
      Name: item.name,
      "Property type": item.company,
      "Area": item.city,
      "Budget": item.zip,
      "mobile number": item.phonenumber,
      "Date Created": formatDate(item.datecreated),
      "Last Contact": formatDate(item.lastcontact),
      Source: item.SourceName,
    }));

    const ws = XLSX.utils.json_to_sheet(wsData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "LeadsData");

    // Save the workbook to a file
    XLSX.writeFile(wb, "LeadsData.xlsx");
  };

  const handlePDFDownload = () => {
    const doc = new jsPDF();

    // Set properties for PDF
    doc.setProperties({
      title: "Leads Data",
      author: "Your Name",
      creator: "Your Application",
    });

    // Add data to PDF
    const tableData = []; // Your data array here

    // Add a header row
    const headers = [
      "Name",
      "Property type",
      "Area",
      "Budget",
      "Phone Number",
      "Source",
    ];
    tableData.push(headers);

    // Add rows
    leadsData.forEach((item) => {
      const row = [];
      row.push(
        item.name,
        item.company,
        item.city,
        item.zip,
        item.phonenumber,
        item.SourceName
      );
      tableData.push(row);
    });

    // Generate table
    doc.autoTable({
      head: [headers],
      body: tableData.slice(1),
    });

    // Save the PDF
    doc.save("LeadsData.pdf");
  };

  const handleReload = () => {
    window.location.reload();
  };

  const handleViewPurchaseOrders = (item) => {
    console.log(item);
    setID(item.id);
  };
  const formatNumber = (num) => {
    if (num >= 1_000_000_000) return (num / 1_000_000_000).toFixed(1) + 'Cr';
    if (num >= 1_000_000) return (num / 1_000_000).toFixed(1) + 'L';
    if (num >= 1_000) return (num / 1_000).toFixed(1) + 'K';
    return num;
  };
  return (
    <Base>
      <div className="page-header">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="page-title">
            <h4>Leads</h4>
            <h6>Manage your leads</h6>
          </div>
        </div>
        <div className="d-flex flex-wrap align-items-center mt-3">
          <div className="flex-fill d-md-flex justify-content-between align-items-center">
            <div className="input-group mb-2 mb-md-0">
              <input
                type="text"
                name="search"
                placeholder="Search..."
                className="form-control"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className="btn btn-primary" type="button">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
          <ul className="table-top-head d-flex list-unstyled mb-0 flex-wrap">
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Pdf"
                onClick={handlePDFDownload}
              >
                <img src="assets/img/icons/pdf.svg" alt="PDF" />
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Excel"
                onClick={handleExcelDownload}
              >
                <img src="assets/img/icons/excel.svg" alt="Excel" />
              </a>
            </li>
            {/* <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print">
                    <i className="fa-solid fa-print fs-5"></i>
                </a>
            </li> */}
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Refresh"
                onClick={handleReload}
              >
                <i className="fa-solid fa-arrow-rotate-left fs-5"></i>
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Collapse"
                id="collapse-header"
              >
                <i className="fa-solid fa-chevron-up fs-6"></i>
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <Link className="btn btn-added" to="/add-lead">
                <i className="fa-solid fa-plus"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="card table-list-card">
        <div className="card-body">
          {/* For all screen sizes */}
          <div className="table-responsive" style={{ minHeight: "24rem" }}>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="no-sort">#</th>
                  <th className="no-sort">Action</th>
                  <th>Name</th>
                  <th>Phone Number</th>
                  <th>Property type</th>
                  <th>preferred Area</th>
                  <th>Budget</th>

                  <th>Assignee</th>
                  <th>Source</th>
                  <th>date created</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <td colSpan={7} className="text-center">
                    <div className="containers m-auto">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  </td>
                ) : (
                  currentItems &&
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="text-start">
                        <div className="dropdown">
                          <a
                            className="action-set"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i
                              className="fa fa-ellipsis-v"
                              aria-hidden="true"
                            ></i>
                          </a>
                          <div className="dropdown-menu">
                            <Link
                              to={`/view-lead`}
                              className="dropdown-item"
                              onClick={() => handleViewPurchaseOrders(item)}
                            >
                              <i className="fa-regular fa-eye me-2"></i>View
                            </Link>
                            <Link
                              className="dropdown-item"
                              to={`/edit-lead`}
                              onClick={() => handleViewPurchaseOrders(item)}
                            >
                              <i className="fa-regular fa-pen-to-square text-info me-2"></i>
                              Edit
                            </Link>
                            <a
                              className="dropdown-item"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasContact"
                              aria-controls="offcanvasContact"
                              onClick={() => showContactModal(item)}
                            >
                              <i className="fa-regular fa-address-book me-2 text-success"></i>
                              Add Contact
                            </a>
                            <a
                              className="dropdown-item"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasReminder"
                              aria-controls="offcanvasReminder"
                              onClick={() => showReminderModal(item)}
                            >
                              <i className="fa-solid fa-business-time me-2 text-info"></i>
                              Set Reminder
                            </a>
                            <a
                              className="dropdown-item"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasFollowup"
                              aria-controls="offcanvasFollowup"
                              onClick={() => showFollowupModal(item)}
                            >
                              <i className="fa-solid fa-reply-all me-2"></i>
                              Followup
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>
                        <Link to={`/view-lead`}>{item.name}</Link>
                      </td>
                      <td>{item.phonenumber}</td>
                      <td><FontAwesomeIcon icon={faBuilding} color="blue" size="lg" className="me-1" />{item.company}</td>

                      <td>{item.city}</td>
                      <td>{formatNumber(item.zip)}</td>
                      <td>{item.staff_names}</td>
                      <td>{item.SourceName}</td>
                      <td>{formatDate(item.dateadded)}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={leadsData.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>

      <AddContact leadOBJ={contactLeadOBJ} />
      <SetReminder leadOBJ={remidnerLeadOBJ} />
      <FollowUp leadOBJ={followupLeadOBJ} />
    </Base>
  );
}
