import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import { Link, useParams } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "../../Config/swal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import "../../Config/swal.css";
import { useLabelContext } from "../../../Context";

export default function EditCreditNotes() {
  // const { creditNotesId } = useParams();
  const { ID } = useLabelContext();
  const creditNotesId = ID;

  const [userDetails, SetUserDetails] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const userDetailsSession = JSON.parse(
      sessionStorage.getItem("userDetails")
    );
    SetUserDetails(userDetailsSession);
  }, []);

  const [creditNoteData, setCreditNoteData] = useState({});
  const [itemsCNData, setItemsCNData] = useState([]);

  const [selectedCustomerid, setSelectedCustomerid] = useState("");
  const [selectedprojectid, setSelectedProjectid] = useState("");
  const [selectedStatusid, setSelectedStatusid] = useState("");
  const [selectedDiscountId, setselectedDiscountId] = useState("");
  const [selectedStaffid, setSelectedStaffid] = useState("");

  const [customersData, setCustomersData] = useState([]);
  const [projectsData, setProjectsData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [taxesData, setTaxesData] = useState([]);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    id: creditNotesId,
    clientid: "",
    project_id: "",
    deleted_customer_name:"",
    number: null,
    prefix: "",
    number_format: null,
    date: "",
    currency: "",
    subtotal: "",
    total_tax: "",
    total: "",
    adjustment: 0.0,
    addedfrom: "",
    status: "",
    clientnote: "",
    adminnote: "",
    discount_percent: "",
    discount_total: "",
    discount_type: "",
    terms: "",
    reference_no: "",
    billing_street: "",
    billing_city: "",
    billing_state: "",
    billing_zip: "",
    billing_country: "",
    shipping_street: "",
    shipping_city: "",
    shipping_state: "",
    shipping_zip: "",
    shipping_country: "",
    include_shipping: "",
    show_quantity_as: "",
    products: [],
  });

  const discountOptions = [
    { value: "No Discount", label: "No Discount" },
    { value: "Before Tax", label: "Before Tax" },
    { value: "After Tax", label: "After Tax" },
  ];

  // const amount = qty * rate + qty * rate * (tax / 100);

  useEffect(() => {
    if (creditNotesId) {
      sessionStorage.setItem("purchaseId", JSON.stringify(creditNotesId));
    }

    const storedPurchaseId = sessionStorage.getItem("purchaseId");
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${API_BASE_CRM_URL}creditnotes/GetCreditNoteById?CNId=${storedPurchaseId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data);
        if (data.Status) {
          setLoading(false);
          setCreditNoteData(data.ResultData.creditNote);
          console.log(data.ResultData.creditNote.number);
          console.log(data.ResultData.creditNote.deleted_customer_name);
          setItemsCNData(data.ResultData.products);
          setFormValues({
            id: data.ResultData.creditNote.id || "",
            clientid: data.ResultData.creditNote.clientid || "",
            number: data.ResultData.creditNote.number || 0,
            deleted_customer_name: data.ResultData.creditNote.deleted_customer_name || "",
            prefix: data.ResultData.creditNote.prefix || "",
            number_format: data.ResultData.creditNote.number_format || 0,
            date: formatDate(data.ResultData.creditNote.date || ""),
            currency: data.ResultData.creditNote.currency || 0,
            subtotal: data.ResultData.creditNote.subtotal || "",
            total_tax: data.ResultData.creditNote.total_tax || "",
            total: data.ResultData.creditNote.total || "",
            adjustment: data.ResultData.creditNote.adjustment || "",
            addedfrom: data.ResultData.creditNote.addedfrom || "",
            status: data.ResultData.creditNote.status || 1,
            clientnote: data.ResultData.creditNote.clientnote || "",
            adminnote: data.ResultData.creditNote.adminnote || "",
            discount_percent: data.ResultData.creditNote.discount_percent || "",
            discount_total: data.ResultData.creditNote.discount_total || "",
            discount_type: data.ResultData.creditNote.discount_type || "",
            reference_no: data.ResultData.creditNote.reference_no || "",
            billing_street: data.ResultData.creditNote.billing_street || "",
            billing_city: data.ResultData.creditNote.billing_city || "",
            billing_state: data.ResultData.creditNote.billing_state,
            billing_zip: data.ResultData.creditNote.billing_zip || "",
            billing_country: data.ResultData.creditNote.billing_country || "",
            shipping_street: data.ResultData.creditNote.shipping_street || "",
            shipping_city: data.ResultData.creditNote.shipping_city || "",
            shipping_state: data.ResultData.creditNote.shipping_state || "",
            shipping_zip: data.ResultData.creditNote.shipping_zip || "",
            shipping_country: data.ResultData.creditNote.shipping_country || "",
            include_shipping: true,
            show_quantity_as: data.ResultData.creditNote.show_quantity_as,
            trems: data.ResultData.creditNote.terms || "",
          });

          setSelectedCustomerid(data.ResultData.creditNote.clientid || "");
          setSelectedProjectid(data.ResultData.creditNote.project_id || "");
          setSelectedStaffid(data.ResultData.creditNote.sale_agent || "");
          setSelectedStatusid(data.ResultData.creditNote.status || "");
        } else {
          setLoading(false);
          console.error("Fetched data is not an array:", data);
          setCreditNoteData([]);
          setItemsCNData([]);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [creditNotesId]);

  const handleCustomerChange = (selectedOption) => {
    setSelectedCustomerid(selectedOption);
    setFormValues((prevData) => ({
      ...prevData,
      clientid: selectedOption,
    }));
  };

  const handleDiscountChange = (selectedone) => {

    console.log(selectedone);
    setselectedDiscountId(selectedone);
  };

  const handleProjectChange = (selectedOption) => {
    setSelectedProjectid(selectedOption);
    setFormValues((prevData) => ({
      ...prevData,
      project_id: selectedOption,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const formatDate = (isoString) => {
    if (!isoString) return "";
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          customersResponse,
          staffResponse,
          taxesResponse,
          productsResponse,
          projectsResponse,
        ] = await Promise.all([
          fetch(`${API_BASE_CRM_URL}GetCustomers`),
          fetch(`${API_BASE_CRM_URL}GetStaff`),
          fetch(`${API_BASE_CRM_URL}GetTaxes`),
          fetch(`${API_BASE_CRM_URL}GetProducts`),
        ]);

        const customersData = await customersResponse.json();
        const staffData = await staffResponse.json();
        const taxesData = await taxesResponse.json();
        const productsData = await productsResponse.json();

        if (customersData.Status) setCustomersData(customersData.ResultData);
        else setCustomersData([]);

        if (staffData.Status) setStaffData(staffData.ResultData);
        else setStaffData([]);

        if (taxesData.Status) setTaxesData(taxesData.ResultData);
        else setTaxesData([]);

        if (productsData.Status) setProductsData(productsData.ResultData);
        else setProductsData([]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
   
      const fetchData = async () => {
        try {
          const response = await fetch(
            `${API_BASE_CRM_URL}customers/GetCustomers`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          if (data.Status) {
            setCustomersData(data.ResultData);
          } else {
            console.error("Fetched data is not an array:", data);
            setCustomersData([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    
  }, []);

 
  useEffect(() => {
    if (selectedCustomerid) {
      const fetchData = async () => {
        try {
          const response = await fetch(
            `${API_BASE_CRM_URL}GetProjectsByCusId?CusID=${selectedCustomerid}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          if (data.Status) {
            setProjectsData(data.ResultData);
          } else {
            console.error("Fetched data is not an array:", data);
            setProjectsData([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [selectedCustomerid]);

  const customerOptions =
    customersData &&
    customersData?.map((item) => ({
      value: item.userid,
      label: item.company,
    }));

  const projectOptions =
    projectsData &&
    projectsData?.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  const productOptions =
    productsData &&
    productsData?.map((item) => ({
      value: item.long_description,
      label: item.description,
      rate: parseFloat(item.rate),
    }));

  const taxOptions =
    taxesData &&
    taxesData.map((item) => ({
      key: item.id,
      value: item.taxrate,
      label: item.name + " " + item.taxrate,
    }));

  useEffect(() => {
    if (itemsCNData) {
      setRows(
        itemsCNData.map((item) => ({
          id: item.id || null,
          rel_id: item.rel_id || null,
          description: item.description,
          long_description: item.long_description || "",
          qty: item.qty || "",
          rate: item.rate || "",
          tax: item.tax || "",
          amount: 0,
          item_order: item.item_order || itemsCNData.length + 1,
        }))
      );
    }
  }, [itemsCNData]);

  const addRow = (product) => {
    if (rows.some((row) => row.description === product.label)) {
      Swal.fire({
        title: "Product already added",
        text: "This product is already in the list.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    const newRow = {
      description: product.label,
      long_description: product.value || "",
      qty: "",
      rate: product.rate,
      tax: "",
      amount: 0,
      item_order: rows.length + 1,
    };

    setRows([...rows, newRow]);
  };

  const handleProductChange = (value, option) => {
    console.log(value, option);
    addRow(option);
  };

  // const handleInputChange = (index, field, value) => {
  //     const newRows = rows.map((row, rowIndex) => {
  //         if (rowIndex === index) {
  //             const updatedRow = { ...row, [field]: value };
  //             return { ...updatedRow, amount: calculateAmount(updatedRow) };
  //         }
  //         return row;
  //     });
  //     setRows(newRows);
  // };

  const handleInputChange = (index, field, value) => {
    const updatedItems = [...itemsCNData];
    updatedItems[index][field] = value;
    setItemsCNData(updatedItems);
  };

  // const handleTaxChange = (index, value) => {
  //     const newRows = rows.map((row, rowIndex) => {
  //         if (rowIndex === index) {
  //             const updatedRow = { ...row, tax: parseFloat(value) || 0 };
  //             return { ...updatedRow, amount: calculateAmount(updatedRow) };
  //         }
  //         return row;
  //     });
  //     setRows(newRows);
  // };

  const handleTaxChange = (index, value) => {
    const updatedItems = [...itemsCNData];
    updatedItems[index].tax = value;
    setItemsCNData(updatedItems);
    // console.log(updatedItems);
  };

  const calculateAmount = ({ qty, rate, tax }) => {
    const quantity = parseFloat(qty) || 0;
    const rateValue = parseFloat(rate) || 0;
    const taxRate = parseFloat(tax) || 0;
    return (quantity * rateValue * (1 + taxRate / 100)).toFixed(2);
  };

  const calculateTotal = () => {
    return rows
      .reduce((total, row) => total + parseFloat(row.qty * row.rate), 0)
      .toFixed(2);
  };

  const handleDelete = (index) => {
    const newRows = rows.filter((_, rowIndex) => rowIndex !== index);
    setRows(newRows);
  };

  //update purchase order
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const updatedFormValues = {
      ...formValues,
      products: rows,
      total: calculateTotal(),
    };

    console.log(updatedFormValues, "data sending to api");

    try {
      const response = await fetch(
        `${API_BASE_CRM_URL}AddOrUpdateCreditNotes`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedFormValues),
        }
      );

      // if (!response.ok) {
      //   throw new Error("Network response was not ok");
      // }

      const result = await response.json();
      console.log(result, "response from api");
      if (result.Status) {
        setLoading(false);
        Swal.fire({
          title: `Credit Notes Updated Successfully.`,
          icon: "success",
          custom: {
            title: "swal-title",
            content: "swal-text",
          },
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate("/credit-notes");
          }
        });
      } else {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: "Failed to submit request.",
          icon: "error",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  return (
    <Base>
      <div className="page-header">
        <div className="add-item d-flex">
          <div className="page-title">
            <h4>Edit Credit Notes</h4>
            <h6>Modify fields for edit credit notes</h6>
          </div>
        </div>
        <ul className="table-top-head">
          <li>
            <Link to="/credit-notes" className="btn btn-added">
              <i className="fa-solid fa-arrow-left me-1"></i>Go to List
            </Link>
          </li>
        </ul>
      </div>

      <div className="card table-list-card">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row m-2">
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Customer</label>
                <Select
                  showSearch
                  placeholder="Choose customer"
                  value={selectedCustomerid || null}
                  onChange={handleCustomerChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={customerOptions}
                  style={{ height: "2.4rem" }}
                />
              </div>

              <div className="d-flex flex-column col-lg-4 col-md-4">
                <label className="form-label">Title</label>
                <input
                  type="text"
                  className="form-control"
                  name="deleted_customer_name"
                  value={formValues?.deleted_customer_name}
                  onChange={handleChange}
                />
              </div>
              {/* <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Project</label>
                <Select
                  showSearch
                  placeholder="Choose project"
                  value={selectedprojectid || null}
                  onChange={handleProjectChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={projectOptions}
                  style={{ height: "2.4rem" }}
                />
              </div> */}
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Discount Type</label>
                <Select
                  showSearch
                  placeholder="Choose status"
                  value={selectedDiscountId || null}
                  onChange={handleDiscountChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={discountOptions}
                  style={{ height: "2.4rem" }}

                  
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={formValues?.date}
                  onChange={handleChange}
                />
              </div>

              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Total Tax</label>
                <input
                  type="text"
                  className="form-control"
                  value={formValues?.total_tax}
                  readOnly
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Total Amount</label>
                <input
                  type="text"
                  className="form-control"
                  value={formValues?.total}
                  readOnly
                />
              </div>
              {/* <div className="d-flex justify-content-between">
                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                  <label className="form-label">Product</label>
                  <Select
                    showSearch
                    placeholder="Choose product"
                    onChange={handleProductChange}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={productOptions}
                    style={{ height: "2.4rem" }}
                  />
                </div>
                <div>
                  <p
                    data-bs-toggle="modal"
                    data-bs-target="#billToModal"
                    style={{ cursor: "pointer", margin: 0 }}
                    className="text-end d-inline-block"
                  >
                    Billing
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="text-primary fs-6 btn"
                    />
                  </p>
                  <p
                    data-bs-toggle="modal"
                    data-bs-target="#shipToModal"
                    style={{ cursor: "pointer", margin: 0, marginLeft: "15px" }}
                    className="text-end d-inline-block"
                  >
                    Shipping
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="text-primary fs-6 btn"
                    />
                  </p>
                </div>
              </div> */}
              {/* <div className="col-12 my-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product</th>
                      <th>Quantity</th>
                      <th>Rate</th>
                      <th>Tax</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemsCNData.map((row, index) => (
                      <tr key={row.id}>
                        <td>{index + 1}</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product name"
                            value={row.description}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "description",
                                e.target.value
                              )
                            }
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Quantity"
                            style={{ width: "5rem" }}
                            value={row.qty}
                            onChange={(e) =>
                              handleInputChange(index, "qty", e.target.value)
                            }
                            onWheel={(e) => e.target.blur()}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Rate"
                            style={{ width: "7rem" }}
                            value={row.rate}
                            onChange={(e) =>
                              handleInputChange(index, "rate", e.target.value)
                            }
                            onWheel={(e) => e.target.blur()}
                          />
                        </td>
                        <td>
                          <Select
                            showSearch
                            placeholder="Choose tax"
                            value={row.tax}
                            onChange={(value) => handleTaxChange(index, value)}
                            options={taxOptions.map((option) => ({
                              ...option,
                              key: option.key,
                            }))}
                            style={{ height: "2.4rem", width: "7rem" }}
                          />
                        </td>
                        <td>{row.qty * row.rate}</td>

                        <td>
                          <button
                            className="btn"
                            onClick={() => handleDelete(index)}
                          >
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              className="text-danger fs-6"
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div> */}
              {/* <div className="col-12 my-3 text-end">
                <h5>Total Amount: {calculateTotal()}</h5>
              </div> */}
              <div className="d-flex flex-column col-lg-12 col-md-12">
                <label className="form-label">Description</label>
                <textarea
                  type="text"
                  className="form-control"
                  name="clientnote"
                  value={formValues?.clientnote}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex flex-column col-2 m-auto mt-2">
                <button className="btn btn-primary" type="submit">
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>

        <div
          className="modal fade"
          id="billToModal"
          tabIndex="-1"
          aria-labelledby="billToModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="billToModalLabel">
                  Bill to
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <label className="form-label">Street</label>
                <input
                  className="form-control"
                  type="text"
                  name="billing_street"
                  value={formValues?.billing_street}
                  onChange={handleChange}
                />
                <label className="form-label">City</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter City"
                  name="billing_city"
                  value={formValues?.billing_city}
                  onChange={handleChange}
                />
                <label className="form-label">State</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter state"
                  name="billing_state"
                  value={formValues?.billing_state}
                  onChange={handleChange}
                />
                <label className="form-label">Pincode</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter pincode"
                  name="billing_zip"
                  value={formValues?.billing_zip}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="shipToModal"
          tabIndex="-1"
          aria-labelledby="shipToModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="shipToModalLabel">
                  Ship to
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <label className="form-label">Street</label>
                <input
                  className="form-control"
                  type="text"
                  name="shipping_street"
                  value={formValues?.shipping_street}
                  onChange={handleChange}
                />
                <label className="form-label">City</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter City"
                  name="shipping_city"
                  value={formValues?.shipping_city}
                  onChange={handleChange}
                />
                <label className="form-label">State</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter state"
                  name="shipping_state"
                  value={formValues?.shipping_state}
                  onChange={handleChange}
                />
                <label className="form-label">Pincode</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter pincode"
                  name="shipping_zip"
                  value={formValues?.shipping_zip}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
}
