import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Base from "../../Config/Base";
import "../../../Assets/popup.css";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Pagination from "../../Config/Pagination";
import "../../Config/search.css";
import SetReminder from "./SetReminder";
import FollowUp from "./FollowUp";
import "../../Config/loader.css";
import Swal from "sweetalert2";
import AddContact from "./AddContact";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useLabelContext } from "../../../Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faBuilding } from "@fortawesome/free-solid-svg-icons";

export default function Customers() {
  const [customersData, setCustomersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [remidnerCusOBJ, setRemidnerCusOBJ] = useState([]);
  const [followupCusOBJ, setFollowupCusOBJ] = useState([]);
  const [addContactOBJ, setAddContactOBJ] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { setID } = useLabelContext();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_CRM_URL}customers/GetCustomers`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        if (data.Status) {
          setCustomersData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setCustomersData([]); // Set an empty array to avoid errors
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatNumber = (num) => {
    if (num >= 1_000_000_000) return (num / 1_000_000_000).toFixed(1) + 'Cr';
    if (num >= 1_000_000) return (num / 1_000_000).toFixed(1) + 'L';
    if (num >= 1_000) return (num / 1_000).toFixed(1) + 'K';
    return num;
  };

  const showReminderModal = (obj) => {
    setRemidnerCusOBJ(obj);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // const filteredItems = customersData.filter(
  //   (item) =>
  //     (item.vat && item.vat.toString().includes(searchTerm.toString())) ||
  //     (item.company &&
  //       item.company.toLowerCase().includes(searchTerm.toLowerCase())) ||
  //     (item.city &&
  //       item.city.toLowerCase().includes(searchTerm.toLowerCase())) ||
  //     (item.phonenumber &&
  //       item.phonenumber.toLowerCase().includes(searchTerm.toLowerCase()))
  // );


  const filteredItems = useMemo(() => {
    const results = customersData.filter(
      (item) =>
        (item.vat && item.vat.toString().includes(searchTerm.toString())) ||
        (item.company &&
          item.company.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.city &&
          item.city.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.phonenumber &&
          item.phonenumber.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    setCurrentPage(1);

    return results;
  }, [customersData, searchTerm]);

  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);


  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const showFollowupModal = (obj) => {
    setFollowupCusOBJ(obj);
  };

  const showAddContactModal = (obj) => {
    setAddContactOBJ(obj);
  };

  const handleExcelDownload = () => {
    if (customersData.length === 0) {
      return; 
    }

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert your data to worksheet format
    const wsData = customersData.map((item) => ({
      Company: item.company,
      "Date Created": formatDate(item.datecreated),
      City: item.city,
      State: item.state,
      Zipcode: item.zip,
      Website: item.website,
    }));

    const ws = XLSX.utils.json_to_sheet(wsData);

   
    XLSX.utils.book_append_sheet(wb, ws, "CustoemrsData");

    XLSX.writeFile(wb, "CustoemrsData.xlsx");
  };

  const handlePDFDownload = () => {
    const doc = new jsPDF();


    doc.setProperties({
      title: "Customers Data",
      author: "Your Name",
      creator: "Your Application",
    });

    
    const tableData = []; 

  
    const headers = [
      "Property type",
      "Name",
      "Mobile",
      "City",
      "State",
      "Budget",

    ];
    tableData.push(headers);

   
    customersData.forEach((item) => {
      const row = [];
      row.push(
        item.company,
        item.name,
        item.phonenumber,
        item.city,
        item.state,
        item.zip,
        item.website
      );
      tableData.push(row);
    });

    // Generate table
    doc.autoTable({
      head: [headers],
      body: tableData.slice(1),
    });

    // Save the PDF
    doc.save("CustomersData.pdf");
  };

  const handleReload = () => {
    window.location.reload();
  };

  const toggleActiveStatus = async (userId, currentStatus) => {
    try {
      const response = await fetch(`${API_BASE_CRM_URL}ActiveCustomer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ UserId: userId, active: currentStatus ? 0 : 1 }),
      });

      const result = await response.json();

      if (result.Status) {
        
        setCustomersData((prevCustomers) =>
          prevCustomers.map((customer) =>
            customer.userid === userId
              ? { ...customer, active: currentStatus ? 0 : 1 }
              : customer
          )
        );
        Swal.fire("Success", "Customer status updated successfully", "success");
      } else {
        Swal.fire("Error", "Failed to update customer status", "error");
      }
    } catch (error) {
      console.error("Error updating customer status:", error);
      Swal.fire(
        "Error",
        "An error occurred while updating customer status",
        "error"
      );
    }
  };

  const handleViewPurchaseOrders = (item) => {
    console.log(item);
    setID(item.userid);
  };

  return (
    <Base>
      <div className="page-header">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="page-title">
            <h4>Customers</h4>
            <h6>Manage your Customers</h6>
          </div>
        </div>
        <div className="d-flex flex-wrap align-items-center mt-3">
          <div className="flex-fill d-md-flex justify-content-between align-items-center">
            <div className="input-group mb-2 mb-md-0">
              <input
                type="text"
                name="search"
                placeholder="Search..."
                className="form-control"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className="btn btn-primary" type="button">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
          <ul className="table-top-head d-flex list-unstyled mb-0 flex-wrap">
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Pdf"
                onClick={handlePDFDownload}
              >
                <img src="assets/img/icons/pdf.svg" alt="PDF" />
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Excel"
                onClick={handleExcelDownload}
              >
                <img src="assets/img/icons/excel.svg" alt="Excel" />
              </a>
            </li>
            {/* <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print">
                    <i className="fa-solid fa-print fs-5"></i>
                </a>
            </li> */}
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Refresh"
                onClick={handleReload}
              >
                <i className="fa-solid fa-arrow-rotate-left fs-5"></i>
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Collapse"
                id="collapse-header"
              >
                <i className="fa-solid fa-chevron-up fs-6"></i>
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <Link className="btn btn-added" to="/add-customer">
                <i className="fa-solid fa-plus"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      
      

        <div className="card table-list-card flex-grow-1">
          <div className="card-body">

            <div className="table-responsive">
              <table className="table datnew w-100">
                <thead>
                  <tr>
                    <th>#</th>
                    <th className="no-sot">Action</th>
                   
                    <th>Name</th>
                    <th>Mobile</th>
                    <th>Property Type</th>
                   <th>Project</th>
                    <th>City</th>
                    <th>Budget</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={8} className="text-center">
                        <div className="containers m-auto">
                          <div className="dot"></div>
                          <div className="dot"></div>
                          <div className="dot"></div>
                          <div className="dot"></div>
                          <div className="dot"></div>
                          <div className="dot"></div>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    currentItems && currentItems.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="">
                          <div className="dropdown">
                            <a
                              className="action-set"
                              href="#"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                            </a>
                            <div className="dropdown-menu">
                              <Link
                                to={`/view-customer`}
                                className="dropdown-item"
                                onClick={() => handleViewPurchaseOrders(item)}
                              >
                                <i className="fa-regular fa-eye me-2"></i>View
                              </Link>
                              <Link
                                className={`dropdown-item ${item.active ? "" : "disabled"}`}
                                to={`/edit-customer`}
                                onClick={() => handleViewPurchaseOrders(item)}
                              >
                                <i className="fa-regular fa-pen-to-square text-info me-2"></i>
                                Edit
                              </Link>
                              <a
                                className={`dropdown-item ${item.active ? "" : "disabled"}`}
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasContact"
                                aria-controls="offcanvasContact"
                                onClick={() => showAddContactModal(item)}
                              >
                                <i className="fa-regular fa-address-book me-2 text-success"></i>
                                Add Contact
                              </a>
                              <a
                                className={`dropdown-item ${item.active ? "" : "disabled"}`}
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasReminder"
                                aria-controls="offcanvasReminder"
                                onClick={() => showReminderModal(item)}
                              >
                                <i className="fa-solid fa-business-time me-2 text-info"></i>
                                Set Reminder
                              </a>
                              <a
                                className={`dropdown-item ${item.active ? "" : "disabled"}`}
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasFollowup"
                                aria-controls="offcanvasFollowup"
                                onClick={() => showFollowupModal(item)}
                              >
                                <i className="fa-solid fa-reply-all me-2"></i>
                                Follow Up
                              </a>
                              <a
                                className="dropdown-item"
                                onClick={() => toggleActiveStatus(item.userid, item.active)}
                              >
                                <i className={`fa-solid fa-toggle-${item.active ? "off text-danger" : "on text-success"} me-2`}></i>
                                {item.active ? "Not Completed" : "Completed"}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td><Link to={`/view-customer`}  onClick={() => handleViewPurchaseOrders(item)}>
                            {item.vat}
                           
                          </Link></td>
                          <td>{item.phonenumber}</td>
                        <td>
                          <Link to={`/view-customer`}>
                          <FontAwesomeIcon icon={faBuilding} color="blue" size="lg" className="me-1"/> { item.company}
                          </Link>
                        </td>
                       <td>{item.projectname}</td>
                       
                        <td>{item.city}</td>
                        <td>{formatNumber(item.zip)}</td>
                        <td>
                          {item.active === 1 ? (
                            <span className="badge bg-outline-success">Completed</span>
                          ) : (
                            <span className="badge bg-outline-danger">Uncompleted</span>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={customersData.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </div>
        </div>
    


      <SetReminder cusOBJ={remidnerCusOBJ} />
      <FollowUp cusOBJ={followupCusOBJ} />
      <AddContact cusOBJ={addContactOBJ} />
    </Base>
  );
}
