import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Base from "../../Config/Base";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLabelContext } from "../../../Context";

export default function ViewProject() {
  const navigate = useNavigate();

  // const { id } = useParams();
  const { ID } = useLabelContext();
  const id = ID;

  const [projectData, setProjectData] = useState({});
  const [projectLocation, setProjectLocation] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const userDetails = sessionStorage.getItem("userDetails");
    if (!userDetails) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    if (id) {
      sessionStorage.setItem("purchaseId", JSON.stringify(id));
    }

    const storedPurchaseId = sessionStorage.getItem("purchaseId");
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${API_BASE_CRM_URL}project/GetProjectDetails?ProId=${storedPurchaseId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        if (data.Status) {
          setProjectData(data.ResultData[0]);
          console.log(data.ResultData[0])
        } else {
          console.error("Fetched data is not an array:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const storedPurchaseId = sessionStorage.getItem("purchaseId");
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${API_BASE_CRM_URL}project/GetLocationByProjectId?ProjID=${storedPurchaseId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        if (data.Status) {
          setProjectLocation(data.ResultData[0]);
        } else {
          console.error("Fetched data is not an array:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const formatDate = (isoString) => {
    if (!isoString) return "";
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <Base>
      {loading ? (
        <p className="laoding-text">
          <div className="spinner-border text-warning" role="status"></div>
        </p>
      ) : (
        ""
      )}
      <div className="page-heade mb-3">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="page-title">
              <h4>Project Details</h4>
              <p className="fw-semibold">View project details</p>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-md-end justify-content-start">
            <Link to="/projects">
              <button
                className="btn btn-primary"
                style={{ borderRadius: "10px" }}
              >
                <i className="fa-solid fa-arrow-left me-2"></i> Go to List
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="col-xxl-12 col-xl-12">
        <div className="card">
          <div
            className="card-body"
            style={{ maxHeight: "100vh", overflowY: "auto" }}
          >
            <form>
              <div className="row m-3">
                <div className="col-md-4 col-lg-4 mb-2">
                  <label className="form-label">Name</label>
                  <input
                    className="form-control"
                    type="text"
                    value={projectData?.name}
                    readOnly
                  />
                </div>
                <div className="col-md-4 col-lg-4 mb-2">
                  <label className="form-label">Customer</label>
                  <input
                    className="form-control"
                    type="text"
                    value={projectData?.cus_name}
                    readOnly
                  />
                </div>
               
                <div className="col-md-4 col-lg-4 mb-2">
                  <label className="form-label">Start Date</label>
                  <input
                    className="form-control"
                    type="date"
                    name="start_date"
                    value={formatDate(projectData?.start_date)}
                    readOnly
                  />
                </div>
                <div className="col-md-4 col-lg-4 mb-2">
                  <label className="form-label">Deadline</label>
                  <input
                    className="form-control"
                    type="date"
                    name="deadline"
                    value={formatDate(projectData?.deadline)}
                    readOnly
                  />
                </div>
                <div className="col-md-4 col-lg-4 mb-2">
                  <label className="form-label">Created Date</label>
                  <input
                    className="form-control"
                    type="date"
                    name="project_created"
                    value={formatDate(projectData?.project_created)}
                    readOnly
                  />
                </div>
                <div className="col-md-4 col-lg-4 mb-2">
                  <label className="form-label">Finished Date</label>
                  <input
                    className="form-control"
                    type="date"
                    name="date_finished"
                    value={formatDate(projectData?.date_finished)}
                    readOnly
                  />
                </div>
                
                <div className="col-md-4 col-lg-4 mb-2">
                  <label className="form-label">Cost of Project</label>
                  <input
                    className="form-control"
                    type="text"
                    name="project_cost"
                    value={projectData?.project_cost}
                    readOnly
                  />
                </div>
             
                <div className="col-md-4 col-lg-4 mb-2">
                  <label className="form-label">Description</label>
                  <input
                    className="form-control"
                    type="text"
                    name="description"
                    value={projectData?.description}
                    readOnly
                  />
                </div>
                <div className="col-md-4 col-lg-4 mb-2">
                  <label className="form-label">Latitude</label>
                  <input
                    className="form-control"
                    type="text"
                    value={projectData?.latitude}
                    readOnly
                  />
                </div>
                <div className="col-md-4 col-lg-4 mb-2">
                  <label className="form-label">Longitude</label>
                  <input
                    className="form-control"
                    type="text"
                    value={projectData?.longitude}
                    readOnly
                  />
                </div>
                <div className="col-md-4 col-lg-4 mb-2">
                  <label className="form-label">Address</label>
                  <input
                    className="form-control"
                    type="text"
                    value={projectData?.notes}
                    readOnly
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Base>
  );
}
