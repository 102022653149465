import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import { Link } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "../../Config/swal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useLabelContext } from "../../../Context";

export default function ViewInvoice() {
  const [userDetails, SetUserDetails] = useState([]);
  const navigate = useNavigate();
  const { ID } = useLabelContext();
  const InvoiceID = ID;
  const [invoiceData, setInvoiceData] = useState([]);
  const [formValues, setFormValues] = useState({
    clientid: "",
    cus_name: "",
    status: "",
    vat:"",
    proj_name: "",
    project_id: "",
    datecreated: "",
    duedate: "",
    sale_agent: "",
    paymentmode: "",
  });

  useEffect(() => {
    const userDetailsSession = JSON.parse(
      sessionStorage.getItem("userDetails")
    );
    SetUserDetails(userDetailsSession);
  }, []);

  const [selectedCustomerid, setSelectedCustomerid] = useState("");
  const [selectedprojectid, setSelectedProjectid] = useState("");
  const [selectedStatusid, setSelectedStatusid] = useState("");
  const [selectedStaffid, setSelectedStaffid] = useState("");
  const [selectedPaymentModeid, setSelectedPaymentModeid] = useState("");

  const [customersData, setCustomersData] = useState([]);
  const [projectsData, setProjectsData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [paymentModesData, setPaymentModesData] = useState([]);
  const [taxesData, setTaxesData] = useState([]);

  const [description, setDescription] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const userDetailsSession = JSON.parse(
      sessionStorage.getItem("userDetails")
    );
    SetUserDetails(userDetailsSession);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_CRM_URL}GetCustomers`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.Status) {
          setCustomersData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setCustomersData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_CRM_URL}GetStaff`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.Status) {
          setStaffData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setStaffData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_CRM_URL}GetPaymentModes`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.Status) {
          setPaymentModesData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setPaymentModesData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_CRM_URL}GetTaxes`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.Status) {
          setTaxesData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setTaxesData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_CRM_URL}GetProducts`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.Status) {
          setProductsData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setProductsData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCustomerid) {
      const fetchData = async () => {
        try {
          const response = await fetch(
            `${API_BASE_CRM_URL}GetProjectsByCusId?CusID=${selectedCustomerid}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          if (data.Status) {
            setProjectsData(data.ResultData);
          } else {
            console.error("Fetched data is not an array:", data);
            setProjectsData([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [selectedCustomerid]);

  useEffect(() => {
    if (InvoiceID) {
      sessionStorage.setItem("purchaseId", JSON.stringify(InvoiceID));
    }

    let storedPurchaseId = sessionStorage.getItem("purchaseId");
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${API_BASE_CRM_URL}GetInvoicesById?ID=${storedPurchaseId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data);
        if (data.Status) {
          setLoading(false);
          setRows(data.ResultData.items);
          console.log(data.ResultData.details);
          setFormValues({
            clientid: data.ResultData.details[0].clientid,
            cus_name: data.ResultData.details[0].cus_name,
            vat:data.ResultData.details[0].vat,
            status: data.ResultData.details[0].status,
            project_id: data.ResultData.details[0].project_id,
            proj_name: data.ResultData.details[0].proj_name,
            datecreated: formatDate(data.ResultData.details[0].date),
            duedate: formatDate(data.ResultData.details[0].duedate),
            sale_agent: data.ResultData.details[0].sale_agent,
            paymentmode: data.ResultData.details[0].allowed_payment_modes,
            total:data.ResultData.details[0].total,
          });
          console.log(data.ResultData.details);
          setSelectedCustomerid(data.ResultData.details[0].customer_code || "");
          setSelectedProjectid(data.ResultData.project || "");
        } else {
          console.error("Fetched data is not an array:", data);
          setLoading(false);
          setInvoiceData([]);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [InvoiceID]);

  const formatDate = (isoString) => {
    if (!isoString) return "";
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const customerOptions =
    customersData &&
    customersData.map((item) => ({
      value: item.userid,
      label: item.company,
    }));

  const projectOptions =
    projectsData &&
    projectsData.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  const paymentModeOptions =
    paymentModesData &&
    paymentModesData.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  const productOptions =
    productsData &&
    productsData.map((item) => ({
      value: item.long_description,
      label: item.description,
      rate: parseFloat(item.rate),
    }));

  const staffOptions =
    staffData &&
    staffData.map((item) => ({
      value: item.staffid,
      label: item.firstname + " " + item.lastname,
    }));

  const taxOptions =
    taxesData &&
    taxesData.map((item) => ({
      key: item.id,
      value: item.taxrate,
      label: item.name + " " + item.taxrate,
    }));

  const statusOptions = [
    { value: 1, label: "Draft" },
    { value: 2, label: "Sent" },
    { value: 5, label: "Expired" },
    { value: 3, label: "Declined" },
    { value: 4, label: "Accepted" },
  ];

  const handleCustomerChange = (selectedOption) => {
    setSelectedCustomerid(selectedOption);
    const label = customersData.filter(
      (item) => item.userid === selectedOption
    );
    // console.log(label[0].company);
    setFormValues((prevData) => ({
      ...prevData,
      clientid: selectedOption,
      cus_name: label[0].company,
    }));
  };

  const handleProjectChange = (selectedone) => {
    setSelectedProjectid(selectedone);
    const label = projectsData.filter((item) => item.id === selectedone);
    console.log(label);
    setFormValues((prevData) => ({
      ...prevData,
      project_id: selectedone,
      proj_name: label[0].name,
    }));
  };

  const handleStatusChange = (selectedone) => {
    setSelectedStatusid(selectedone);
    setFormValues((prevData) => ({
      ...prevData,
      status: selectedone,
    }));
  };

  const handleStaffChange = (selectedone) => {
    setSelectedStaffid(selectedone);
    setFormValues((prevData) => ({
      ...prevData,
      sale_agent: selectedone,
    }));
  };

  const handlePaymentModeChange = (selectedone) => {
    setSelectedPaymentModeid(selectedone);
    const label = paymentModesData.filter((item) => item.id === selectedone);
    console.log(label[0].name);
    setFormValues((prevData) => ({
      ...prevData,
      paymentmode: label[0].name,
    }));
  };

  const handleInvoiceDateChange = (event) => {
    setInvoiceDate(event.target.value);
    setFormValues((prevData) => ({
      ...prevData,
      datecreated: event.target.value,
    }));
  };

  const handledueDateChange = (event) => {
    setDueDate(event.target.value);
    setFormValues((prevData) => ({
      ...prevData,
      duedate: event.target.value,
    }));
  };

  const addRow = (product) => {
    // Check if the product is already in the list
    if (rows.some((row) => row.description === product.label)) {
      Swal.fire({
        title: "Product already added",
        text: "This product is already in the list.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    const newRow = {
      description: product.label,
      long_description: product.value || "",
      qty: "",
      rate: product.rate,
      tax: "",
      amount: 0,
      item_order: rows.length + 1,
    };

    setRows([...rows, newRow]);
    // setSelectedProducts(null);
  };

  const handleProductChange = (value, option) => {
    addRow(option);
  };

  const handleInputChange = (index, field, value) => {
    const newRows = rows.map((row, rowIndex) => {
      if (rowIndex === index) {
        const updatedRow = { ...row, [field]: parseFloat(value) || 0 };
        return { ...updatedRow, amount: calculateAmount(updatedRow) };
      }
      return row;
    });
    setRows(newRows);
  };

  const handleTaxChange = (index, value) => {
    const newRows = rows.map((row, rowIndex) => {
      if (rowIndex === index) {
        const updatedRow = { ...row, tax: parseFloat(value) || 0 };
        return { ...updatedRow, amount: calculateAmount(updatedRow) };
      }
      return row;
    });
    setRows(newRows);
  };

  const calculateAmount = ({ qty, rate, tax }) => {
    const quantity = parseFloat(qty) || 0;
    const rateValue = parseFloat(rate) || 0;
    const taxRate = parseFloat(tax) || 0;
    return (quantity * rateValue * (1 + taxRate / 100)).toFixed(2);
  };

  const calculateTotal = () => {
    return rows
      .reduce((total, row) => total + parseFloat(row.qty * row.rate), 0)
      .toFixed(2);

  };

  const handleDelete = (index) => {
    const newRows = rows.filter((_, rowIndex) => rowIndex !== index);
    setRows(newRows);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const totalAmount = calculateTotal();

    const InvoiceData = {
      id: InvoiceID,
      clientid: formValues.clientid,
      // cus_name: formValues.cus_name,
      status: formValues.status,
      proj_name: formValues.proj_name,
      project_id: formValues.project_id,
      datecreated: formValues.datecreated,
      date: formValues.datecreated,
      duedate: formValues.duedate,
      sale_agent: formValues.sale_agent,
      paymentmode: formValues.paymentmode,
      total: calculateTotal(),
      products: rows,
    };

    console.log(InvoiceData, "data sending to api");

    try {
      const response = await fetch(`${API_BASE_CRM_URL}UpdateInvoice`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(InvoiceData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log(result, "rrrrrrrrrrrrrrrrrrr");
      if (result.Status) {
        setLoading(false);
        Swal.fire({
          title: `Invoice Added Successfully.`,
          icon: "success",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            // window.location.reload();
            navigate("/invoice");
          }
        });
      } else {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: "Failed to submit request.",
          icon: "error",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  return (
    <Base>
      <div className="page-header">
        <div className="add-item d-flex">
          <div className="page-title">
            <h4>View Invoice</h4>
            <h6>Modify fields for invocie</h6>
          </div>
        </div>
        <ul className="table-top-head">
          <li>
            <Link to="/purchase-order" className="btn btn-added">
              <i className="fa-solid fa-arrow-left me-1"></i>Go to List
            </Link>
          </li>
        </ul>
      </div>

      <div className="card table-list-card">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row m-2">
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Customer</label>
                <Select
                  showSearch
                  placeholder="Choose Customer"
                  value={formValues.vat || null}
                //   onChange={handleCustomerChange}
                //   filterOption={(input, option) =>
                //     (option?.label ?? "")
                //       .toLowerCase()
                //       .includes(input.toLowerCase())
                //   }
                //   options={customerOptions}
                //   style={{ height: "2.4rem" }}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Project</label>
                <Select
                  showSearch
                  placeholder="Choose project"
                  value={formValues.proj_name || null}
                //   onChange={handleProjectChange}
                //   filterOption={(input, option) =>
                //     (option?.label ?? "")
                //       .toLowerCase()
                //       .includes(input.toLowerCase())
                //   }
                //   options={projectOptions}
                //   style={{ height: "2.4rem" }}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Status</label>
                <Select
                  showSearch
                  placeholder="Choose status"
                  value={formValues.status || null}
                //   onChange={handleStatusChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={statusOptions}
                //   style={{ height: "2.4rem" }}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Invoice Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={formValues.datecreated}
                //   onChange={handleInvoiceDateChange}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Due Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={formValues.duedate}
                //   onChange={handledueDateChange}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Sale Agent</label>
                <Select
                  showSearch
                  placeholder="Choose agent"
                  value={formValues.sale_agent || null}
                  onChange={handleStaffChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={staffOptions}
                  style={{ height: "2.4rem" }}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Payment Mode</label>
                <Select
                  showSearch
                  placeholder="Choose payment mode"
                  value={formValues.paymentmode || null}
                //   onChange={handlePaymentModeChange}
                //   filterOption={(input, option) =>
                //     (option?.label ?? "")
                //       .toLowerCase()
                //       .includes(input.toLowerCase())
                //   }
                //   options={paymentModeOptions}
                //   style={{ height: "2.4rem" }}
                />
              </div>
              {/* <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Product</label>
                <Select
                  showSearch
                  placeholder="Choose product"
                  onChange={handleProductChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={productOptions}
                  style={{ height: "2.4rem" }}
                />
              </div> */}
               <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Agent Commission</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formValues.total}
                                    readOnly
                                />
                            </div>
             
             
              <div className="d-flex flex-column col-lg-12 col-md-12">
                <label className="form-label">Description</label>
                <textarea
                  type="text"
                  className="form-control"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter notes..."
                />
              </div>
             
            </div>
          </form>
        </div>
      </div>
    </Base>
  );
}
