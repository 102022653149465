import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import { API_BASE_CRM_URL } from "../../Config/Config";
import "../../Config/loader.css";
import Pagination from "../../Config/Pagination";
import {
  startOfWeek,
  startOfMonth,
  subMonths,
  subYears,
  format,
} from "date-fns";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function CustomersReport() {
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0];
  const [fromDate, setFromDate] = useState(today);
  const [selectedOption, setSelectedOption] = useState("");
  const [toDate, setToDate] = useState(today);
  const [currentPage, setCurrentPage] = useState(1);
  const [customersData, setCustomersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const userDetails = sessionStorage.getItem("userDetails");
    if (!userDetails) {
      navigate("/");
    }
  }, [navigate]);

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    calculateFromDate(selectedValue);
  };

  const calculateFromDate = (option) => {
    const today = new Date();
    let newFromDate;

    switch (option) {
      case "This Week":
        newFromDate = startOfWeek(today, { weekStartsOn: 1 }); // Monday as the start of the week
        break;
      case "This Month":
        newFromDate = startOfMonth(today);
        break;
      case "Last 3 Months":
        newFromDate = subMonths(today, 3);
        break;
      case "1 Year":
        newFromDate = subYears(today, 1);
        break;
      default:
        newFromDate = "";
        break;
    }

    setFromDate(newFromDate);
  };

  const handleSubmit = () => {
    if (fromDate) {
      const formattedDate = format(fromDate, "yyyy-MM-dd");
      const fetchcustomersData = async () => {
        setLoading(true);
        try {
          const response = await fetch(
            `${API_BASE_CRM_URL}reports/GetCustomersReport?fromDate=${formattedDate}&toDate=${toDate}`
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();

          // Ensure data is an array
          if (data.Status) {
            setCustomersData(data.ResultData);
            console.log(data.ResultData);
          } else {
            setCustomersData([]);
            console.error("Unexpected response format:", data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchcustomersData();
    } else {
      alert("Please Choose From Date");
    }
  };

  const filteredItems = customersData.filter(
    (item) =>
      item.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.phonenumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.state.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (item.default_language &&
        item.default_language.toLowerCase().includes(searchTerm.toLowerCase()))
  );
  const itemsPerPage = 10;

  // Calculate indices for slicing data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const handleExcelDownload = () => {
    if (customersData.length === 0) {
      return; // Don't download if there is no data
    }

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert your data to worksheet format
    const wsData = customersData.map((item) => ({
      Organisation: item.company,
      Mobile: item.phonenumber,
      Vat: item.vat,
      Website: item.website,
      Language: item.default_language,
      City: item.city,
      State: item.state,
    }));

    const ws = XLSX.utils.json_to_sheet(wsData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "customersData");

    // Save the workbook to a file
    XLSX.writeFile(wb, "customersReport.xlsx");
  };

  const handlePDFDownload = () => {
    const doc = new jsPDF();

    // Set properties for PDF
    doc.setProperties({
      title: "Customers",
      author: "Your Name",
      creator: "Your Application",
    });

    // Add data to PDF
    const tableData = []; // Your data array here

    // Add a header row
    const headers = [
      "Organisation",
      "Mobile",
      "Vat",
      "Website",
      "Language",
      "City",
      "State",
    ];
    tableData.push(headers);

    // Add rows
    customersData.forEach((item) => {
      const row = [];
      row.push(
        item.company,
        item.phonenumber,
        item.vat,
        item.website,
        item.default_language,
        item.city,
        item.state
      );
      tableData.push(row);
    });

    // Generate table
    doc.autoTable({
      head: [headers],
      body: tableData.slice(1),
    });
    // Save the PDF
    doc.save("customersReport.pdf");
  };

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Base>
      <div>
        <div className="page-header">
          <div className="d-flex justify-content-between flex-wrap">
            <div className="page-title">
              <h4>Customers Reports</h4>
              <h6>Manage your Customers Reports</h6>
            </div>
          </div>
          <div className="d-flex flex-wrap align-items-center mt-3">
            <div className="flex-fill d-md-flex justify-content-between align-items-center">
              <div className="input-group mb-2 mb-md-0">
                <input
                  type="text"
                  name="search"
                  placeholder="Search..."
                  className="form-control"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button className="btn btn-primary" type="button">
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
            <ul className="table-top-head d-flex list-unstyled mb-0 flex-wrap">
              <li className="ms-2 ms-md-3">
                <a
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Pdf"
                  onClick={handlePDFDownload}
                >
                  <img src="assets/img/icons/pdf.svg" alt="PDF" />
                </a>
              </li>
              <li className="ms-2 ms-md-3">
                <a
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Excel"
                  onClick={handleExcelDownload}
                >
                  <img src="assets/img/icons/excel.svg" alt="Excel" />
                </a>
              </li>
              {/* <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print">
                    <i className="fa-solid fa-print fs-5"></i>
                </a>
            </li> */}
              <li className="ms-2 ms-md-3">
                <a
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Refresh"
                  onClick={handleReload}
                >
                  <i className="fa-solid fa-arrow-rotate-left fs-5"></i>
                </a>
              </li>
              <li className="ms-2 ms-md-3">
                <a
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Collapse"
                  id="collapse-header"
                >
                  <i className="fa-solid fa-chevron-up fs-6"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-3 col-xl-3 col-lg-3">
            <label className="form-label">From Date</label>
            <input
              type="date"
              className="form-control"
              value={fromDate}
              onChange={handleFromDateChange}
            />
          </div>
          <div className="col-md-3 col-xl-3 col-lg-3">
            <label className="form-label">To Date</label>
            <input
              type="date"
              className="form-control"
              value={toDate}
              onChange={handleToDateChange}
            />
          </div>
          <div className="col-md-3 col-xl-3 col-lg-3">
            <label className="form-label">Report Period</label>
            <select
              className="form-select"
              onChange={handleSelectChange}
              value={selectedOption}
            >
              <option disabled value="">
                Choose
              </option>
              <option value="This Week">This Week</option>
              <option value="This Month">This Month</option>
              <option value="Last 3 Months">Last 3 Months</option>
              <option value="1 Year">1 Year</option>
            </select>
          </div>
          <div className="col-md-3 col-xl-3 col-lg-3 rounded mt-4">
            <button className="btn btn-primary" onClick={handleSubmit}>
              <i className="fa-solid fa-magnifying-glass"></i>
            </button>
          </div>
        </div>

        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-responsve ">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Property type</th>
                    <th>Phone Number</th>
                    <th>Email</th>
                   
                    <th>City</th>
                    <th>budget</th>
                    <th>Added</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <td colSpan={12} className="text-center">
                      <div className="loader m-auto my-5"></div>
                    </td>
                  ) : (
                    currentItems &&
                    currentItems.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.vat}</td>
                        <td className="text-primary">{item.company}</td>
                        <td>{item.phonenumber}</td>
                        <td>{item.default_language}</td>
                        
                       
                        <td>{item.city}</td>
                        <td>{item.zip}</td>
                        
                        <td>{formatDate(item.datecreated)}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={customersData.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
}
