
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";


export default function EditLocation({ locationObj }) {

    const [locationData, setLocationData] = useState([]);

    useEffect(() => {
        setLocationData(locationObj);
    }, [locationObj]);
    
    // const formatDate = (dateString) => {
    //     const date = new Date(dateString);
    //     const day = String(date.getDate()).padStart(2, '0');
    //     const month = String(date.getMonth() + 1).padStart(2, '0');
    //     const year = date.getFullYear();
    //     return `${day}-${month}-${year}`;
    // };

    return (
        <div >
            <style>
                {`
                    .offcanvas.custom-offcanvas {
                        width: 400px !important; /* Adjust this as needed */
                    }

                    @media (max-width: 576px) {
                        .offcanvas.custom-offcanvas {
                        width: 70% !important; /* Adjust the percentage as needed */
                        }import Customers from './../Customers/Customers';

                    }
                `}
            </style>

            <div
                className="offcanvas offcanvas-end custom-offcanvas"
                tabIndex="-1"
                id="offcanvasRightEdit"
                aria-labelledby="offcanvasRightLabel"
            >
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">Edit Location Details</h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="offcanvas-body">
                    <div className="row">
                        <div className="mb-2">
                            <label className="form-label">Project Name</label>
                            <input
                                type="text"
                                className="form-control"
                                value={locationData?.pro_name}
                                readOnly
                            />
                        </div>
                        {/* <div className="mb-2">
                            <label className="form-label">Customer Name</label>
                            <input
                                type="text"
                                className="form-control"
                                value={locationData?.cus_name}
                                readOnly
                            />
                        </div> */}
                        <div className="mb-2">
                            <label className="form-label">Latitude</label>
                            <input
                                type="text"
                                className="form-control"
                                value={locationData?.latitude}
                                readOnly
                            />
                        </div>
                        <div className="mb-2">
                            <label className="form-label">Longitude</label>
                            <input
                                type="text"
                                className="form-control"
                                value={locationData?.longitude}
                                readOnly
                            />
                        </div>
                        <div className="mb-2">
                            <label className="form-label">Address</label>
                            <textarea
                                type="text"
                                className="form-control"
                                value={locationData?.address}
                                readOnly
                            />
                        </div>
                        <div className="mb-2">
                            <label className="form-label">Description</label>
                            <textarea
                                type="text"
                                className="form-control"
                                value={locationData?.notes}
                                readOnly
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

EditLocation.propTypes = {
    locationObj: PropTypes.array.isRequired,
};
  