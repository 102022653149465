import React, { useState } from "react";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";
import '../../Config/swal.css';

export default function AddSource() {
    const [sourceName, setSourceName] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const newStatus = {
            name: sourceName,
        };
    
        try {
            const response = await fetch(`${API_BASE_CRM_URL}leads/AddLeadSource`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newStatus),
            });
    
            const result = await response.json();
            if (result.Status) {
                setLoading(false);
                Swal.fire({
                    title: `Lead Source Add Successfully.`,
                    icon: "success",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        window.location.reload();
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Failed to submit request.",
                    icon: "error",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                });
            }
        } catch (error) {
            console.error('Error adding source:', error);
            alert('Failed to add source. Please try again.');
        }
    };

    return (
        <div className="modal fade" id="add-source">
            <div className="modal-dialog modal-dialog-centered custom-modal-two">
                <div className="modal-content">
                    <div className="page-wrapper-new p-0">
                        <div className="content">
                            <div className="modal-header border-0 custom-modal-header">
                                <div className="page-title">
                                    <h4>Add Source</h4>
                                </div>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body custom-modal-body new-employee-field">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="mb-3">
                                            <label className="form-label">Name</label>
                                            <input 
                                                type="text" 
                                                className="form-control"
                                                placeholder="Enter source name"
                                                value={sourceName}
                                                onChange={(e) => setSourceName(e.target.value)} 
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-footer-btn">
                                        <button type="button" className="btn btn-cancel me-2" data-bs-dismiss="modal">
                                            Cancel
                                        </button>
                                        <button type="submit" className="btn btn-submit">
                                            {loading ? 'Submitting...' : 'Submit'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
