
import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import { Link, useLocation } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Select } from 'antd';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import '../../Config/swal.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";


export default function AddCreditNotes() {

    const [userDetails, SetUserDetails] = useState([]);
    const navigate = useNavigate();

    const [billFormData, setBillFormData] = useState({
        street: "",
        city: "",
        state: "",
        pincode: "",
        country: "",
    });
    const [billApplyData, setBillApplyData] = useState(null);
    const billhandleChange = (e) => {
        setBillFormData({ ...billFormData, [e.target.name]: e.target.value });
    };
    const handleBillApply = () => {
        setBillApplyData(billFormData);
    };


    const [shipFormData, setShipFormData] = useState({
        street: "",
        city: "",
        state: "",
        pincode: "",
        country: "",
    });
    const [shipApplyData, setShipApplyData] = useState(null);
    const shiphandleChange = (e) => {
        setShipFormData({ ...shipFormData, [e.target.name]: e.target.value });
    };
    const handleShipApply = () => {
        setShipApplyData(shipFormData);
    };

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);


    const [referenceNo, setReferenceNo] = useState([]);
    const [title,setTitle] = useState([]);

    const [selectedCustomerid, setSelectedCustomerid] = useState("");
    const [selectedprojectid, setSelectedProjectid] = useState("");
    const [selectedDiscountId, setselectedDiscountId] = useState("");
    const [selectedStaffid, setSelectedStaffid] = useState("");

    const [customersData, setCustomersData] = useState([]);
    const [projectsData, setProjectsData] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [staffData, setStaffData] = useState([]);
    const [taxesData, setTaxesData] = useState([]);
    const [country,setCountry]=useState("")

    const [description, setDescription] = useState('');
    const [terms, setTerms] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [estimateDate, setEstimateDate] = useState('');
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const { customerId } = location.state || {};

    useEffect(()=>{
        setSelectedCustomerid(customerId)
    }
)

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [
                    customersResponse,
                    staffResponse,
                    taxesResponse,
                    productsResponse,
                    countriesResponse,
                    projectsResponse
                ] = await Promise.all([
                    fetch(`${API_BASE_CRM_URL}customers/GetCustomers`),
                    fetch(`${API_BASE_CRM_URL}GetStaff`),
                    fetch(`${API_BASE_CRM_URL}GetTaxes`),
                    fetch(`${API_BASE_CRM_URL}GetProducts`),
                    fetch(`${API_BASE_CRM_URL}GetCountries`),
                ]);
    
                const customersData = await customersResponse.json();
                const staffData = await staffResponse.json();
                const taxesData = await taxesResponse.json();
                const productsData = await productsResponse.json();
                const countriesData = await countriesResponse.json();

                if (customersData.Status) setCustomersData(customersData.ResultData);
                else setCustomersData([]);
    
                if (staffData.Status) setStaffData(staffData.ResultData);
                else setStaffData([]);
    
                if (taxesData.Status) setTaxesData(taxesData.ResultData);
                else setTaxesData([]);
    
                if (productsData.Status) setProductsData(productsData.ResultData);
                else setProductsData([]);
    
                if (countriesData.Status) setCountry(countriesData.ResultData);
                else setCountry([]);
    
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        fetchData();
    }, []);    

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(`${API_BASE_CRM_URL}customers/GetCustomers`);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            if (data.Status) {
                setCustomersData(data.ResultData);
                console.log(data.ResultData)
            } else {
                console.error("Fetched data is not an array:", data);
                setCustomersData([]);
            }
          } catch (error) {
                console.error("Error fetching data:", error);
          }
        };
        fetchData();
      }, [selectedCustomerid]);

    const customerOptions = customersData && customersData.map((item) => ({
        value: item.userid,
        label: item.vat,
    }));

    // const projectOptions = projectsData && projectsData.map((item) => ({
    //     value: item.userid,
    //     label: item.vat,
    // }));

    // const productOptions = productsData && productsData.map((item) => ({
    //     value: item.id,
    //     label: item.description,
    //     rate: parseFloat(item.rate),
    // }));

    const staffOptions = staffData && staffData.map((item) => ({
        value: item.staffid,
        label: item.firstname + " " + item.lastname,
    }));

    const taxOptions = taxesData && taxesData.map((item) => ({
        key: item.id,
        value: item.taxrate,
        label: item.name + " " + item.taxrate,
    }));

    const countryOptions=country && country.map((item)=>({
        value: item.country_id,
        label: item.country
    }))

    const discountOptions = [
        { "value": "No Discount", "label": "No Discount" },
        { "value": "Before Tax", "label": "Before Tax" },
        { "value": "After Tax", "label": "After Tax" },

    ];

    const handleCustomerChange = (selectedone) => {
        setSelectedCustomerid(selectedone);
    };

    const handleProjectChange = (selectedone) => {
        setSelectedProjectid(selectedone);
    };

    const handleDiscountChange = (selectedone) => {
        setselectedDiscountId(selectedone);
    };

    // const handleStaffChange = (selectedone) => {
    //     setSelectedStaffid(selectedone);
    // };

    const handleEstimateDateChange = (event) => {
        setEstimateDate(event.target.value);
    };

    const handleReferenceNo = (event) => {
        setReferenceNo(event.target.value)
    }

    const handleTitleChange = (event) => {
        setTitle(event.target.value)
    }

    const handleExpiryDateChange = (event) => {
        setExpiryDate(event.target.value);
    };

    const addRow = (product) => {
        // Check if the product is already in the list
        if (rows.some(row => row.description === product.label)) {
            Swal.fire({
                title: "Product already added",
                text: "This product is already in the list.",
                icon: "warning",
                confirmButtonText: "OK"
            });
            return;
        }
    
        // Initialize qty and tax as empty, as these will be filled in later
        const qty = 0;
        const rate = product.rate || 0;
        const tax = 0;
        
        // Calculate the amount (initially zero)
        const amount = (qty * rate) + (qty * rate * (tax / 100));
    
        const newRow = {
            description: product.label,
            long_description: product.value || '',
            qty: '',
            rate: rate,
            tax: '',
            amount: amount,
            item_order: rows.length + 1
        };
    
        setRows([...rows, newRow]);
        // setSelectedProducts(null);
    };

    const handleProductChange = (value, option) => {
        addRow(option);
    };

    const handleInputChange = (index, field, value) => {
        const newRows = rows.map((row, rowIndex) => {
            if (rowIndex === index) {
                const updatedRow = { ...row, [field]: parseFloat(value) || 0 };
                return { ...updatedRow, amount: calculateAmount(updatedRow) };
            }
            return row;
        });
        setRows(newRows);
    };

    const handleTaxChange = (index, value) => {
        const newRows = rows.map((row, rowIndex) => {
            if (rowIndex === index) {
                const updatedRow = { ...row, tax: parseFloat(value) || 0 };
                return { ...updatedRow, amount: calculateAmount(updatedRow) };
            }
            return row;
        });
        setRows(newRows);
    };

    const calculateAmount = ({ qty, rate, tax }) => {
        const quantity = parseFloat(qty) || 0;
        const rateValue = parseFloat(rate) || 0;
        const taxRate = parseFloat(tax) || 0;
        return (quantity * rateValue * (1 + taxRate / 100)).toFixed(2);
    };

    const calculateTotal = () => {
        return rows.reduce((total, row) => total + parseFloat(row.amount || 0), 0).toFixed(2);
    };


    const handleDelete = (index) => {
        const newRows = rows.filter((_, rowIndex) => rowIndex !== index);
        setRows(newRows);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const totalAmount = calculateTotal();

        const purchaseData = {
            clientid: selectedCustomerid,
            // project_id: selectedprojectid,
            deleted_customer_name:title,
            number: 789,
            prefix: "CN",
            number_format: "000",
            date: estimateDate,
            currency: 2,
            subtotal: totalAmount,
            total_tax: 100,
            total: totalAmount,
            adjustment: 0,
            addedfrom: userDetails.staffid,
            status: 1,
            clientnote: description,
            adminnote: description,
            discount_percent: 10,
            discount_total: 100,
            discount_type: 1,
            terms: terms,
            reference_no: referenceNo,
            billing_street: billFormData.street,
            billing_city: billFormData.city,
            billing_state: billFormData.state,
            billing_zip: billFormData.pincode,
            billing_country: 2,
            shipping_street: shipFormData.street,
            shipping_city: shipFormData.city,
            shipping_state: shipFormData.state,
            shipping_zip: shipFormData.pincode,
            shipping_country: 2,
            include_shipping: true,
            show_quantity_as: 1,
            products: rows,
        };

        console.log(purchaseData, 'data sending to api');

        try {
            const response = await fetch(`${API_BASE_CRM_URL}creditnotes/AddOrUpdateCreditNotes`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(purchaseData),
            });

           

            const result = await response.json();
            console.log(result, 'rrrrrrrrrrrrrrrrrrr');
            if (result.Status) {
                setLoading(false);
                Swal.fire({
                    title: `Credit Notes Added Successfully.`,
                    icon: "success",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        // window.location.reload();
                        navigate('/credit-notes')
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Failed to submit request.",
                    icon: "error",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                });
            }
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };


    return (
        <Base>

            <div className="page-header">
                <div className="add-item d-flex">
                    <div className="page-title">
                        <h4>Add Credit Notes</h4>
                        <h6>Enter fields for add credit note</h6>
                    </div>
                </div>
                <ul className="table-top-head">
                    <li>
                        <Link to='/credit-notes' className="btn btn-added"><i className="fa-solid fa-arrow-left me-1"></i>Go to List</Link>
                    </li>
                </ul>
            </div>

            <div className="card table-list-card">
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row m-2">
                        <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Title</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Title"
                                    value={title}
                                    onChange={handleTitleChange}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Customer</label>
                                <Select
                                    showSearch
                                    placeholder="Choose customer"
                                    value={selectedCustomerid || null}
                                    onChange={handleCustomerChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={customerOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div>
                            {/* <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Project</label>
                                <Select
                                    showSearch
                                    placeholder="Choose project"
                                    value={selectedprojectid || null}
                                    onChange={handleProjectChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={projectOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div> */}
                            {/* <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Discount Type</label>
                                <Select
                                    showSearch
                                    placeholder="Choose status" 
                                    value={selectedDiscountId || null}
                                    onChange={handleDiscountChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={discountOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div> */}
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={estimateDate}
                                    onChange={handleEstimateDateChange}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Reference No</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Reference no"
                                    value={referenceNo}
                                    onChange={handleReferenceNo}
                                />
                            </div>

                            {/* <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Product</label>
                                <Select
                                    showSearch
                                    placeholder="Choose product"
                                    onChange={handleProductChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={productOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div> */}
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2 ">
                                <div className="d-flex">
                                    <p className="mt-1 bold">Bill To</p>
                                    <FontAwesomeIcon icon={faPenToSquare} className="text-info fs-6 btn"
                                        data-bs-toggle="modal" data-bs-target="#billToModal" />
                                </div>
                                {billApplyData && (
                                    <div className="mt-2">
                                        <p><strong>Street:</strong> {billApplyData.street}</p>
                                        <p><strong>City:</strong> {billApplyData.city}</p>
                                        <p><strong>State:</strong> {billApplyData.state}</p>
                                        <p><strong>Pincode:</strong> {billApplyData.pincode}</p>
                                        <p><strong>Country:</strong> {billApplyData.country}</p>
                                    </div>
                                )}
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2 ">
                                <div className="d-flex">
                                    <p className="mt-1 bold">Ship To</p>
                                    <FontAwesomeIcon icon={faPenToSquare} className="text-info fs-6 btn"
                                        data-bs-toggle="modal" data-bs-target="#shipToModal" />
                                </div>
                                {shipApplyData && (
                                    <div className="mt-2">
                                        <p><strong>Street:</strong> {shipApplyData.street}</p>
                                        <p><strong>City:</strong> {shipApplyData.city}</p>
                                        <p><strong>State:</strong> {shipApplyData.state}</p>
                                        <p><strong>Pincode:</strong> {shipApplyData.pincode}</p>
                                        <p><strong>Country:</strong> {shipApplyData.country}</p>
                                    </div>
                                )}

                            </div>
                            {/* <div className="col-12 my-3">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Product</th>
                                            <th>Quantity</th>
                                            <th>Rate</th>
                                            <th>Tax</th>
                                            <th>Amount</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows.map((row, index) => (
                                            <tr key={row.id}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Product name"
                                                        value={row.description}
                                                        onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                                                        readOnly
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Quantity"
                                                        style={{ width: '5rem' }}
                                                        value={row.qty}
                                                        onChange={(e) => handleInputChange(index, 'qty', e.target.value)}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Rate"
                                                        style={{ width: '7rem' }}
                                                        value={row.rate}
                                                        onChange={(e) => handleInputChange(index, 'rate', e.target.value)}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                </td>
                                                <td>
                                                    <Select
                                                        showSearch
                                                        placeholder="Choose tax"
                                                        value={row.tax}
                                                        onChange={(value) => handleTaxChange(index, value)}
                                                        options={taxOptions.map(option => ({ ...option, key: option.key }))}
                                                        style={{ height: '2.4rem', width: '7rem' }}
                                                    />
                                                </td>
                                                <td>{row.amount}</td>
                                                <td>
                                                    <button className="btn" onClick={() => handleDelete(index)}>
                                                        <FontAwesomeIcon icon={faTrashCan} className="text-danger fs-6" />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-12 my-3 text-end">
                                <h5>Total Amount: {calculateTotal()}</h5>
                            </div> */}
                            <div className="d-flex flex-column col-lg-12 col-md-12">
                                <label className="form-label">Description</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Enter notes..."
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-12 col-md-12">
                                <label className="form-label">Terms & Conditions</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    value={terms}
                                    onChange={(e) => setTerms(e.target.value)}
                                    placeholder="Enter notes..."
                                />
                            </div>
                            <div className="d-flex flex-column col-2 m-auto mt-2">
                                <button className="btn btn-primary" type="submit">{loading ? 'Submitting...' : 'Submit'}</button>
                            </div>
                        </div>
                    </form>


                    <div className="modal fade" id="billToModal" tabindex="-1" aria-labelledby="billToModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="billToModalLabel">Bill to</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <label className="form-label">Street</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter street"
                                        name="street"
                                        value={billFormData.street}
                                        onChange={billhandleChange}
                                    />
                                    <label className="form-label">City</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter City"
                                        name="city"
                                        value={billFormData.city}
                                        onChange={billhandleChange}
                                    />
                                    <label className="form-label">State</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter state"
                                        name="state"
                                        value={billFormData.state}
                                        onChange={billhandleChange}
                                    />
                                    <label className="form-label">Pincode</label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder="Enter pincode"
                                        name="pincode"
                                        value={billFormData.pincode}
                                        onChange={billhandleChange}
                                    />
                                    <label className="form-label">Country</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Country"
                                        name="country"
                                        value={billFormData.country}
                                        onChange={billhandleChange}
                                    />
                                </div>
                                <div className="modal-footer justify-content-arond">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-bs-dismiss="modal"
                                        onClick={handleBillApply}
                                    >
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="shipToModal" tabindex="-1" aria-labelledby="shipToModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="shipToModalLabel">Ship to</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <label className="form-label">Street</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter street"
                                        name="street"
                                        value={shipFormData.street}
                                        onChange={shiphandleChange}
                                    />
                                    <label className="form-label">City</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter City"
                                        name="city"
                                        value={shipFormData.city}
                                        onChange={shiphandleChange}
                                    />
                                    <label className="form-label">State</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter state"
                                        name="state"
                                        value={shipFormData.state}
                                        onChange={shiphandleChange}
                                    />
                                    <label className="form-label">Pincode</label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder="Enter pincode"
                                        name="pincode"
                                        value={shipFormData.pincode}
                                        onChange={shiphandleChange}
                                    />
                                    <label className="form-label">Country</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Country"
                                        name="country"
                                        value={shipFormData.country}
                                        onChange={shiphandleChange}
                                    />
                                    {/* <Select
                                        showSearch
                                        placeholder="Choose tax"
                                        // value={}
                                        // onChange={(value) => handleTaxChange(index, value)}
                                        options={countryOptions}
                                        style={{ height: '2.4rem', width: '7rem' }}
                                    /> */}
                                </div>
                                <div className="modal-footer justify-content-arond">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-bs-dismiss="modal"
                                        onClick={handleShipApply}
                                    >
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Base>
    )
}